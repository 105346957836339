/*
* App Global CSS
* ----------------------------------------------------------------------------
* Put style rules here that you want to apply globally. These styles are for
* the entire app and not just one component. Additionally, this file can be
* used as an entry point to import other CSS/Sass files to be included in the
* output CSS.
* For more information on global stylesheets, visit the documentation:
* https://ionicframework.com/docs/layout/global-stylesheets
*/
/* Core CSS required for Ionic components to work properly */
@import "@ionic/angular/css/core.css";
/* Basic CSS for apps built with Ionic */
@import "@ionic/angular/css/normalize.css";
@import "@ionic/angular/css/structure.css";
@import "@ionic/angular/css/typography.css";
@import "@ionic/angular/css/display.css";
/* Optional CSS utils that can be commented out */
@import "@ionic/angular/css/padding.css";
@import "@ionic/angular/css/float-elements.css";
@import "@ionic/angular/css/text-alignment.css";
@import "@ionic/angular/css/text-transformation.css";
@import "@ionic/angular/css/flex-utils.css";
/**
* Ionic Dark Mode
* -----------------------------------------------------
* For more info, please see:
* https://ionicframework.com/docs/theming/dark-mode
*/
/* @import "@ionic/angular/css/palettes/dark.always.css"; */
/* @import "@ionic/angular/css/palettes/dark.class.css"; */
@import "@ionic/angular/css/palettes/dark.system.css";
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap");
// Primary ==>> font-family: "Poppins", sans-serif;
// Secondary ==>> font-family: $secondaryFont;
// -webkit-...  // For Webkit browser(Chrome, Safari...)
// -moz-...     // For Mozilla browser
// -o-...       // For Opera browser
// -ms-...      // For Microsoft browser
// none...      // For all browser(Newest version)
/**
 ** Variables 
 */
$bp-small: 48em; // 768px
$bp-medium: 64em; // 1024px
$bp-large: 85.375em; // 1366px
$bp-xlarge: 120em; // 1920px
$bp-xxlarge: 160em; // 2560px
//** Media Queries
$mq-small: "(min-width: #{$bp-small})";
$mq-medium: "(min-width: #{$bp-medium})";
$mq-large: "(min-width: #{$bp-large})";
$mq-xlarge: "(min-width: #{$bp-xlarge})";
$mq-xxlarge: "(min-width: #{$bp-xxlarge})";
$mq-retina: "(-webkit-min-device-pixel-ratio: 2),(min-resolution: 192dpi)";
//** Font-Size
$base-font-size: 1em;
//** Line-Height
$base-line-height: 1.5;
$header-line-height: 1.25;
//** Spacing
$base-spacing: $base-font-size;
//** Colors
$black: #151515;
$dark-grey: #242424;
$grey: #666;
$base-accent-color: #fc4842;
$base-font-color: #ccc;
//** Transition Settings
$transition6: 0.6s all ease;
$base-transition-duration: 0.3s;
$base-transition-timing: ease-in-out;
//** Backgrounds
$base-background-color: $dark-grey;
//** Fonts
$primaryFont: "Poppins", sans-serif;
$secondaryFont: "Inter", sans-serif;
/**
  * Base Styles
  */
body,
html,
* {
  font-family: $primaryFont;
}
body,
html {
  inset: 0;
  position: fixed;
  z-index: 1;
  width: 100% !important;
  height: 100% !important;
  min-height: 100% !important;
  min-height: -moz-available !important;
  min-height: -webkit-fill-available !important;
  min-height: fill-available !important;
  max-height: 100% !important;
  max-height: -moz-available !important;
  max-height: -webkit-fill-available !important;
  max-height: fill-available !important;
}
body {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
.one3d-main-app-container {
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  margin-top: 0;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  height: 100%;
}
body,
html {
  user-select: none;
  background-color: #000;
}
.one3d-canvas {
  flex: 0 0 calc(100% - 360px);
  height: calc(100% - 110px);
  position: relative;
}
.one3d-canvas canvas {
  width: 100% !important;
  height: 100% !important;
}
.one3d-brand-logo {
  top: 20px;
  left: 20px;
  z-index: 9;
  user-select: none;
  position: absolute;
}
.one3d-main-loader-percentage {
  top: 35%;
  left: 50%;
  opacity: 1;
  z-index: 99;
  user-select: none;
  position: absolute;
  visibility: visible;
  -webkit-transition: 0.6s all ease;
  -moz-transition: 0.6s all ease;
  -ms-transition: 0.6s all ease;
  -o-transition: 0.6s all ease;
  transition: 0.6s all ease;
  -webkit-transform: translate(-50%, -50%) scale(1);
  -moz-transform: translate(-50%, -50%) scale(1);
  -ms-transform: translate(-50%, -50%) scale(1);
  -o-transform: translate(-50%, -50%) scale(1);
  transform: translate(-50%, -50%) scale(1);
}
.one3d-loader-percentage-text {
  --text-size: clamp(14rem, 14vw + 14rem, 14rem);
  position: relative;
  padding: 0;
  margin: 0;
  font-size: var(--text-size);
  font-weight: 900;
  letter-spacing: 0px;
  background-image: linear-gradient(
    243.26deg,
    rgba(249, 176, 66, 0.8) 22.47%,
    rgba(87, 163, 255, 0.8) 40.65%,
    rgba(87, 163, 255, 0.2) 92.74%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  -ms-background-clip: text;
  -o-background-clip: text;
  background-clip: text;
  color: #000;
  text-shadow: 0 0px 12px rgba(249, 176, 66, 0.4),
    0 0px 12px rgba(87, 163, 255, 0.4);
  font-family: "Inter", sans-serif;
}
/* These are non-standard but well-supported so best to use with `@supports` (if you really want to use them) */
@supports (text-stroke: 4px transparent) {
  .one3d-loader-percentage-text {
    color: #000;
    font-weight: 800;
    text-stroke: 4px transparent;
  }
}
@supports (-webkit-text-stroke: 4px transparent) {
  .one3d-loader-percentage-text {
    color: #000;
    font-weight: 800;
    -webkit-text-stroke: 4px transparent;
  }
}
.one3d-tap-to-unmute {
  position: relative;
}
.one3d-speaker-cursor-box {
  left: 50%;
  bottom: 30px;
  user-select: none;
  position: absolute;
  pointer-events: none;
  -webkit-transition: 0.6s all ease;
  -moz-transition: 0.6s all ease;
  -ms-transition: 0.6s all ease;
  -o-transition: 0.6s all ease;
  transition: 0.6s all ease;
  -webkit-transform: translate(-50%, 0);
  -moz-transform: translate(-50%, 0);
  -ms-transform: translate(-50%, 0);
  -o-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
}
.one3d-speaker-cursor {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.one3d-speaker-cursor-icon {
  width: 64px;
  height: 64px;
  display: flex;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  -webkit-backdrop-filter: blur(2px);
  -moz-backdrop-filter: blur(2px);
  -ms-backdrop-filter: blur(2px);
  -o-backdrop-filter: blur(2px);
  backdrop-filter: blur(2px);
  background: -webkit-radial-gradient(
    50% 50% at 50% 50%,
    rgba(255, 199, 245, 0.5) 0%,
    rgba(153, 119, 147, 0.5) 100%
  );
  background: -moz-radial-gradient(
    50% 50% at 50% 50%,
    rgba(255, 199, 245, 0.5) 0%,
    rgba(153, 119, 147, 0.5) 100%
  );
  background: -ms-radial-gradient(
    50% 50% at 50% 50%,
    rgba(255, 199, 245, 0.5) 0%,
    rgba(153, 119, 147, 0.5) 100%
  );
  background: -o-radial-gradient(
    50% 50% at 50% 50%,
    rgba(255, 199, 245, 0.5) 0%,
    rgba(153, 119, 147, 0.5) 100%
  );
  background: radial-gradient(
    50% 50% at 50% 50%,
    rgba(255, 199, 245, 0.5) 0%,
    rgba(153, 119, 147, 0.5) 100%
  );
}
.one3d-speaker-cursor-text {
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  background: -webkit-linear-gradient(
    90deg,
    #cf7dc3 0%,
    #a485e1 51.76%,
    #57a4ff 100%
  );
  background: -moz-linear-gradient(
    90deg,
    #cf7dc3 0%,
    #a485e1 51.76%,
    #57a4ff 100%
  );
  background: -ms-linear-gradient(
    90deg,
    #cf7dc3 0%,
    #a485e1 51.76%,
    #57a4ff 100%
  );
  background: -o-linear-gradient(
    90deg,
    #cf7dc3 0%,
    #a485e1 51.76%,
    #57a4ff 100%
  );
  background: linear-gradient(90deg, #cf7dc3 0%, #a485e1 51.76%, #57a4ff 100%);
  background-size: 300% auto;
  color: #000;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-fill-color: transparent;
  -webkit-animation: shineGradientAnimation 4s linear infinite;
  -moz-animation: shineGradientAnimation 4s linear infinite;
  -ms-animation: shineGradientAnimation 4s linear infinite;
  -o-animation: shineGradientAnimation 4s linear infinite;
  animation: shineGradientAnimation 4s linear infinite;
  position: relative;
  right: 8px;
}
.one3d-tap-to-unmute-button {
  left: 50%;
  opacity: 1;
  bottom: 30px;
  user-select: none;
  position: absolute;
  visibility: visible;
  -webkit-transition: 0.6s all ease;
  -moz-transition: 0.6s all ease;
  -ms-transition: 0.6s all ease;
  -o-transition: 0.6s all ease;
  transition: 0.6s all ease;
  -webkit-transform: translate(-50%, 0) scale(1);
  -moz-transform: translate(-50%, 0) scale(1);
  -ms-transform: translate(-50%, 0) scale(1);
  -o-transform: translate(-50%, 0) scale(1);
  transform: translate(-50%, 0) scale(1);
}
.one3d-fade-out {
  opacity: 0 !important;
  visibility: hidden !important;
  -webkit-transition: 0.6s all ease;
  -moz-transition: 0.6s all ease;
  -ms-transition: 0.6s all ease;
  -o-transition: 0.6s all ease;
  transition: 0.6s all ease;
}
.one3d-fade-in {
  opacity: 1 !important;
  visibility: visible !important;
  -webkit-transition: 0.6s all ease;
  -moz-transition: 0.6s all ease;
  -ms-transition: 0.6s all ease;
  -o-transition: 0.6s all ease;
  transition: 0.6s all ease;
}
.one3d-sine-wave-top-path,
.one3d-sine-wave-bottom-path {
  stroke-width: 4px;
}
.one3d-sine-wave-bottom {
  top: 0;
  left: 0;
  position: absolute;
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}
.rectangle-red {
  top: 50%;
  z-index: 9;
  left: -60px;
  position: absolute;
  -webkit-transform: translate(0, -50%);
  -moz-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  -o-transform: translate(0, -50%);
  transform: translate(0, -50%);
  -webkit-animation: moverRed 1s infinite alternate;
  -moz-animation: moverRed 1s infinite alternate;
  -ms-animation: moverRed 1s infinite alternate;
  -o-animation: moverRed 1s infinite alternate;
  animation: moverRed 1s infinite alternate;
}
.rectangle-blue {
  top: 50%;
  z-index: 9;
  right: -10px;
  position: absolute;
  -webkit-transform: translate(0, -50%);
  -moz-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  -o-transform: translate(0, -50%);
  transform: translate(0, -50%);
  -webkit-animation: moverBlue 1s infinite alternate;
  -moz-animation: moverBlue 1s infinite alternate;
  -ms-animation: moverBlue 1s infinite alternate;
  -o-animation: moverBlue 1s infinite alternate;
  animation: moverBlue 1s infinite alternate;
}
@-webkit-keyframes moverRed {
  0% {
    left: -60px;
  }
  100% {
    left: 36px;
  }
}
@-moz-keyframes moverRed {
  0% {
    left: -60px;
  }
  100% {
    left: 36px;
  }
}
@keyframes moverRed {
  0% {
    left: -60px;
  }
  100% {
    left: 36px;
  }
}
@-webkit-keyframes moverBlue {
  0% {
    right: -10px;
  }
  100% {
    right: 60px;
  }
}
@-moz-keyframes moverBlue {
  0% {
    right: -10px;
  }
  100% {
    right: 60px;
  }
}
@keyframes moverBlue {
  0% {
    right: -10px;
  }
  100% {
    right: 60px;
  }
}
.one3d-tap-anywhere-to-unmute {
  font-size: 14px;
  font-weight: 600;
  text-align: center;
  background: -webkit-linear-gradient(
    90deg,
    #f9b042 0%,
    #fde3c6 51.76%,
    #57a4ff 100%
  );
  background: -moz-linear-gradient(
    90deg,
    #f9b042 0%,
    #fde3c6 51.76%,
    #57a4ff 100%
  );
  background: -ms-linear-gradient(
    90deg,
    #f9b042 0%,
    #fde3c6 51.76%,
    #57a4ff 100%
  );
  background: -o-linear-gradient(
    90deg,
    #f9b042 0%,
    #fde3c6 51.76%,
    #57a4ff 100%
  );
  background: linear-gradient(90deg, #f9b042 0%, #fde3c6 51.76%, #57a4ff 100%);
  background-size: 300% auto;
  color: #000;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  -ms-background-clip: text;
  -o-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;
  -ms-text-fill-color: transparent;
  -o-text-fill-color: transparent;
  text-fill-color: transparent;
  -webkit-animation: shineGradientAnimation 4s linear infinite;
  -moz-animation: shineGradientAnimation 4s linear infinite;
  -ms-animation: shineGradientAnimation 4s linear infinite;
  -o-animation: shineGradientAnimation 4s linear infinite;
  animation: shineGradientAnimation 4s linear infinite;
  position: relative;
  font-family: "Inter", sans-serif;
}
@-webkit-keyframes shineGradientAnimation {
  to {
    background-position: -300% center;
  }
}
@-moz-keyframes shineGradientAnimation {
  to {
    background-position: -300% center;
  }
}
@keyframes shineGradientAnimation {
  to {
    background-position: -300% center;
  }
}
.one3d-cursor {
  top: 0;
  left: 0;
  margin: 0;
  width: 28px;
  height: 28px;
  z-index: 1000;
  position: fixed;
  user-select: none;
  border-radius: 50%;
  pointer-events: none;
  backdrop-filter: blur(2px);
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
  border: 2px solid rgba(31, 242, 255, 0.9);
  -webkit-box-shadow: 0 0 4px 0 rgba(31, 242, 255, 0.3);
  -moz-box-shadow: 0 0 4px 0 rgba(31, 242, 255, 0.3);
  -ms-box-shadow: 0 0 4px 0 rgba(31, 242, 255, 0.3);
  -o-box-shadow: 0 0 4px 0 rgba(31, 242, 255, 0.3);
  box-shadow: 0 0 4px 0 rgba(31, 242, 255, 0.3);
}
.one3d-cursor::after {
  content: "";
  top: 50%;
  left: 50%;
  margin: 0;
  width: 4px;
  height: 4px;
  z-index: 1000;
  position: fixed;
  user-select: none;
  border-radius: 50%;
  pointer-events: none;
  -webkit-transition: all 0.1s ease-out;
  -moz-transition: all 0.1s ease-out;
  -ms-transition: all 0.1s ease-out;
  -o-transition: all 0.1s ease-out;
  transition: all 0.1s ease-out;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  background: rgba(31, 242, 255, 0.9);
  -webkit-box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.3);
  -ms-box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.3);
  -o-box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.3);
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.3);
}
.one3d-cursor.active {
  -webkit-animation: cursorAnim 0.3s linear 0s infinite forwards;
  -moz-animation: cursorAnim 0.3s linear 0s infinite forwards;
  -ms-animation: cursorAnim 0.3s linear 0s infinite forwards;
  -o-animation: cursorAnim 0.3s linear 0s infinite forwards;
  animation: cursorAnim 0.3s linear 0s infinite forwards;
}
@-webkit-keyframes cursorAnim {
  0% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
    -webkit-box-shadow: 0 0 0 5px rgba(31, 242, 255, 0.8);
    -moz-box-shadow: 0 0 0 5px rgba(31, 242, 255, 0.8);
    -ms-box-shadow: 0 0 0 5px rgba(31, 242, 255, 0.8);
    -o-box-shadow: 0 0 0 5px rgba(31, 242, 255, 0.8);
    box-shadow: 0 0 0 5px rgba(31, 242, 255, 0.8);
  }
  50% {
    -webkit-transform: scale(2);
    -moz-transform: scale(2);
    -ms-transform: scale(2);
    -o-transform: scale(2);
    transform: scale(2);
    -webkit-box-shadow: 0 0 10px 5px rgba(31, 242, 255, 0.5);
    -moz-box-shadow: 0 0 10px 5px rgba(31, 242, 255, 0.5);
    -ms-box-shadow: 0 0 10px 5px rgba(31, 242, 255, 0.5);
    -o-box-shadow: 0 0 10px 5px rgba(31, 242, 255, 0.5);
    box-shadow: 0 0 10px 5px rgba(31, 242, 255, 0.5);
  }
  100% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
    -webkit-box-shadow: 0 0 0 5px rgba(31, 242, 255, 0);
    -moz-box-shadow: 0 0 0 5px rgba(31, 242, 255, 0);
    -ms-box-shadow: 0 0 0 5px rgba(31, 242, 255, 0);
    -o-box-shadow: 0 0 0 5px rgba(31, 242, 255, 0);
    box-shadow: 0 0 0 5px rgba(31, 242, 255, 0);
  }
}
@-moz-keyframes cursorAnim {
  0% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
    -webkit-box-shadow: 0 0 0 5px rgba(31, 242, 255, 0.8);
    -moz-box-shadow: 0 0 0 5px rgba(31, 242, 255, 0.8);
    -ms-box-shadow: 0 0 0 5px rgba(31, 242, 255, 0.8);
    -o-box-shadow: 0 0 0 5px rgba(31, 242, 255, 0.8);
    box-shadow: 0 0 0 5px rgba(31, 242, 255, 0.8);
  }
  50% {
    -webkit-transform: scale(2);
    -moz-transform: scale(2);
    -ms-transform: scale(2);
    -o-transform: scale(2);
    transform: scale(2);
    -webkit-box-shadow: 0 0 10px 5px rgba(31, 242, 255, 0.5);
    -moz-box-shadow: 0 0 10px 5px rgba(31, 242, 255, 0.5);
    -ms-box-shadow: 0 0 10px 5px rgba(31, 242, 255, 0.5);
    -o-box-shadow: 0 0 10px 5px rgba(31, 242, 255, 0.5);
    box-shadow: 0 0 10px 5px rgba(31, 242, 255, 0.5);
  }
  100% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
    -webkit-box-shadow: 0 0 0 5px rgba(31, 242, 255, 0);
    -moz-box-shadow: 0 0 0 5px rgba(31, 242, 255, 0);
    -ms-box-shadow: 0 0 0 5px rgba(31, 242, 255, 0);
    -o-box-shadow: 0 0 0 5px rgba(31, 242, 255, 0);
    box-shadow: 0 0 0 5px rgba(31, 242, 255, 0);
  }
}
@keyframes cursorAnim {
  0% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
    -webkit-box-shadow: 0 0 0 5px rgba(31, 242, 255, 0.8);
    -moz-box-shadow: 0 0 0 5px rgba(31, 242, 255, 0.8);
    -ms-box-shadow: 0 0 0 5px rgba(31, 242, 255, 0.8);
    -o-box-shadow: 0 0 0 5px rgba(31, 242, 255, 0.8);
    box-shadow: 0 0 0 5px rgba(31, 242, 255, 0.8);
  }
  50% {
    -webkit-transform: scale(2);
    -moz-transform: scale(2);
    -ms-transform: scale(2);
    -o-transform: scale(2);
    transform: scale(2);
    -webkit-box-shadow: 0 0 10px 5px rgba(31, 242, 255, 0.5);
    -moz-box-shadow: 0 0 10px 5px rgba(31, 242, 255, 0.5);
    -ms-box-shadow: 0 0 10px 5px rgba(31, 242, 255, 0.5);
    -o-box-shadow: 0 0 10px 5px rgba(31, 242, 255, 0.5);
    box-shadow: 0 0 10px 5px rgba(31, 242, 255, 0.5);
  }
  100% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
    -webkit-box-shadow: 0 0 0 5px rgba(31, 242, 255, 0);
    -moz-box-shadow: 0 0 0 5px rgba(31, 242, 255, 0);
    -ms-box-shadow: 0 0 0 5px rgba(31, 242, 255, 0);
    -o-box-shadow: 0 0 0 5px rgba(31, 242, 255, 0);
    box-shadow: 0 0 0 5px rgba(31, 242, 255, 0);
  }
}
.one3d-park-mode-button-box {
  position: absolute;
  left: 50%;
  z-index: 9;
  bottom: 20px;
  -webkit-transition: 1s all ease;
  -moz-transition: 1s all ease;
  -ms-transition: 1s all ease;
  -o-transition: 1s all ease;
  transition: 1s all ease;
  -webkit-transform: translate(-50%, 0) scale(1);
  -moz-transform: translate(-50%, 0) scale(1);
  -ms-transform: translate(-50%, 0) scale(1);
  -o-transform: translate(-50%, 0) scale(1);
  transform: translate(-50%, 0) scale(1);
}
.one3d-park-mode-button {
  background: rgba(16, 15, 17, 0.05);
  border: 1px solid rgba(255, 255, 255, 0.1);
  -webkit-box-shadow: -2px 0px 2px 0px rgba(255, 255, 255, 0.9) inset;
  -moz-box-shadow: -2px 0px 2px 0px rgba(255, 255, 255, 0.9) inset;
  -ms-box-shadow: -2px 0px 2px 0px rgba(255, 255, 255, 0.9) inset;
  -o-box-shadow: -2px 0px 2px 0px rgba(255, 255, 255, 0.9) inset;
  box-shadow: -2px 0px 2px 0px rgba(255, 255, 255, 0.9) inset;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
  letter-spacing: 0.5px;
  color: #fff;
  text-decoration: none;
  padding: 4px;
  border-radius: 40px;
  height: 76px;
  width: 224px;
  cursor: pointer;
  -webkit-transition: 0.5s all ease;
  -moz-transition: 0.5s all ease;
  -ms-transition: 0.5s all ease;
  -o-transition: 0.5s all ease;
  transition: 0.5s all ease;
  backdrop-filter: blur(10px);
}
.one3d-park-mode-button-left {
  flex: 0 0 auto;
  text-align: right;
  padding-left: 6px;
  width: calc(100% - 68px);
  padding-right: 18px;
  position: relative;
}
.one3d-animate-park-mode-button .one3d-park-mode-button-left {
  left: 0px;
  -webkit-animation: swipeToRightText 1s linear 0s 1 forwards;
  -moz-animation: swipeToRightText 1s linear 0s 1 forwards;
  -ms-animation: swipeToRightText 1s linear 0s 1 forwards;
  -o-animation: swipeToRightText 1s linear 0s 1 forwards;
  animation: swipeToRightText 1s linear 0s 1 forwards;
}
@-webkit-keyframes swipeToRightText {
  from {
    left: 0px;
  }
  to {
    left: 50px;
  }
}
@-moz-keyframes swipeToRightText {
  from {
    left: 0px;
  }
  to {
    left: 50px;
  }
}
@keyframes swipeToRightText {
  from {
    left: 0px;
  }
  to {
    left: 50px;
  }
}
.one3d-park-mode-button-right {
  width: 68px;
  height: 68px;
  border-radius: 50%;
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  background: -webkit-linear-gradient(
    180deg,
    rgba(31, 242, 255, 0.36) 0%,
    rgba(92, 244, 253, 0.6) 100%
  );
  background: -moz-linear-gradient(
    180deg,
    rgba(31, 242, 255, 0.36) 0%,
    rgba(92, 244, 253, 0.6) 100%
  );
  background: -ms-linear-gradient(
    180deg,
    rgba(31, 242, 255, 0.36) 0%,
    rgba(92, 244, 253, 0.6) 100%
  );
  background: -o-linear-gradient(
    180deg,
    rgba(31, 242, 255, 0.36) 0%,
    rgba(92, 244, 253, 0.6) 100%
  );
  background: linear-gradient(
    180deg,
    rgba(31, 242, 255, 0.36) 0%,
    rgba(92, 244, 253, 0.6) 100%
  );
}
.one3d-park-mode-button-text {
  top: 0px;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.4;
  text-align: right;
  position: relative;
  color: rgba(31, 242, 255, 0.6);
}
.one3d-animate-park-mode-button .one3d-park-mode-button-right {
  right: 0px;
  -webkit-animation: swipeToLeft 1s linear 0s 1 forwards;
  -moz-animation: swipeToLeft 1s linear 0s 1 forwards;
  -ms-animation: swipeToLeft 1s linear 0s 1 forwards;
  -o-animation: swipeToLeft 1s linear 0s 1 forwards;
  animation: swipeToLeft 1s linear 0s 1 forwards;
}
@-webkit-keyframes swipeToLeft {
  from {
    right: 0px;
  }
  to {
    right: 146px;
  }
}
@-moz-keyframes swipeToLeft {
  from {
    right: 0px;
  }
  to {
    right: 146px;
  }
}
@keyframes swipeToLeft {
  from {
    right: 0px;
  }
  to {
    right: 146px;
  }
}
.one3d-park-mode-p,
.one3d-park-mode-part {
  -webkit-animation: oscillateInfiniteY 1s linear 0s infinite forwards;
  -moz-animation: oscillateInfiniteY 1s linear 0s infinite forwards;
  -ms-animation: oscillateInfiniteY 1s linear 0s infinite forwards;
  -o-animation: oscillateInfiniteY 1s linear 0s infinite forwards;
  animation: oscillateInfiniteY 1s linear 0s infinite forwards;
}
@-webkit-keyframes oscillateInfiniteY {
  from {
    -webkit-transform: translateY(0px);
    -moz-transform: translateY(0px);
    -ms-transform: translateY(0px);
    -o-transform: translateY(0px);
    transform: translateY(0px);
  }
  to {
    -webkit-transform: translateY(-8px);
    -moz-transform: translateY(-8px);
    -ms-transform: translateY(-8px);
    -o-transform: translateY(-8px);
    transform: translateY(-8px);
  }
}
@-moz-keyframes oscillateInfiniteY {
  from {
    -webkit-transform: translateY(0px);
    -moz-transform: translateY(0px);
    -ms-transform: translateY(0px);
    -o-transform: translateY(0px);
    transform: translateY(0px);
  }
  to {
    -webkit-transform: translateY(-8px);
    -moz-transform: translateY(-8px);
    -ms-transform: translateY(-8px);
    -o-transform: translateY(-8px);
    transform: translateY(-8px);
  }
}
@keyframes oscillateInfiniteY {
  from {
    -webkit-transform: translateY(0px);
    -moz-transform: translateY(0px);
    -ms-transform: translateY(0px);
    -o-transform: translateY(0px);
    transform: translateY(0px);
  }
  to {
    -webkit-transform: translateY(-8px);
    -moz-transform: translateY(-8px);
    -ms-transform: translateY(-8px);
    -o-transform: translateY(-8px);
    transform: translateY(-8px);
  }
}
.one3d-drive-mode-button-box {
  position: absolute;
  left: 50%;
  z-index: 9;
  bottom: 20px;
  -webkit-transition: 1s all ease;
  -moz-transition: 1s all ease;
  -ms-transition: 1s all ease;
  -o-transition: 1s all ease;
  transition: 1s all ease;
  -webkit-transform: translate(-50%, 0) scale(1);
  -moz-transform: translate(-50%, 0) scale(1);
  -ms-transform: translate(-50%, 0) scale(1);
  -o-transform: translate(-50%, 0) scale(1);
  transform: translate(-50%, 0) scale(1);
}
.one3d-drive-mode-button {
  background: rgba(16, 15, 17, 0.05);
  border: 1px solid rgba(255, 255, 255, 0.1);
  -webkit-transition: 0.5s all ease;
  -moz-transition: 0.5s all ease;
  -ms-transition: 0.5s all ease;
  -o-transition: 0.5s all ease;
  transition: 0.5s all ease;
  -webkit-box-shadow: -2px 0px 2px 0px rgba(255, 255, 255, 0.9) inset;
  -moz-box-shadow: -2px 0px 2px 0px rgba(255, 255, 255, 0.9) inset;
  -ms-box-shadow: -2px 0px 2px 0px rgba(255, 255, 255, 0.9) inset;
  -o-box-shadow: -2px 0px 2px 0px rgba(255, 255, 255, 0.9) inset;
  box-shadow: -2px 0px 2px 0px rgba(255, 255, 255, 0.9) inset;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
  letter-spacing: 0.5px;
  color: #fff;
  text-decoration: none;
  padding: 4px;
  border-radius: 40px;
  height: 76px;
  width: 224px;
  cursor: pointer;
  backdrop-filter: blur(10px);
}
.one3d-drive-mode-button-left {
  width: 68px;
  height: 68px;
  border-radius: 50%;
  flex: 0 0 auto;
  display: flex;
  z-index: 2;
  align-items: center;
  justify-content: center;
  background: -webkit-linear-gradient(
    180deg,
    rgba(199, 218, 255, 0.4) 0%,
    rgba(121, 144, 188, 0.4) 100%
  );
  background: -moz-linear-gradient(
    180deg,
    rgba(199, 218, 255, 0.4) 0%,
    rgba(121, 144, 188, 0.4) 100%
  );
  background: -ms-linear-gradient(
    180deg,
    rgba(199, 218, 255, 0.4) 0%,
    rgba(121, 144, 188, 0.4) 100%
  );
  background: -o-linear-gradient(
    180deg,
    rgba(199, 218, 255, 0.4) 0%,
    rgba(121, 144, 188, 0.4) 100%
  );
  background: linear-gradient(
    180deg,
    rgba(199, 218, 255, 0.4) 0%,
    rgba(121, 144, 188, 0.4) 100%
  );
  position: relative;
  left: 0;
}
.one3d-animate-drive-mode-button .one3d-drive-mode-button-left {
  left: 0px;
  -webkit-animation: swipeToRight 1s linear 0s 1 forwards;
  -moz-animation: swipeToRight 1s linear 0s 1 forwards;
  -ms-animation: swipeToRight 1s linear 0s 1 forwards;
  -o-animation: swipeToRight 1s linear 0s 1 forwards;
  animation: swipeToRight 1s linear 0s 1 forwards;
}
@-webkit-keyframes swipeToRight {
  from {
    left: 0px;
  }
  to {
    left: 146px;
  }
}
@-moz-keyframes swipeToRight {
  from {
    left: 0px;
  }
  to {
    left: 146px;
  }
}
@keyframes swipeToRight {
  from {
    left: 0px;
  }
  to {
    left: 146px;
  }
}
.one3d-drive-mode-button-right {
  flex: 0 0 auto;
  text-align: left;
  padding-left: 6px;
  position: relative;
  right: 0;
  width: calc(100% - 68px);
}
.one3d-animate-drive-mode-button .one3d-drive-mode-button-right {
  right: 0px;
  -webkit-animation: swipeToLeftText 1s linear 0s 1 forwards;
  -moz-animation: swipeToLeftText 1s linear 0s 1 forwards;
  -ms-animation: swipeToLeftText 1s linear 0s 1 forwards;
  -o-animation: swipeToLeftText 1s linear 0s 1 forwards;
  animation: swipeToLeftText 1s linear 0s 1 forwards;
}
@-webkit-keyframes swipeToLeftText {
  from {
    right: 0px;
  }
  to {
    right: 50px;
  }
}
@-moz-keyframes swipeToLeftText {
  from {
    right: 0px;
  }
  to {
    right: 50px;
  }
}
@keyframes swipeToLeftText {
  from {
    right: 0px;
  }
  to {
    right: 50px;
  }
}
.one3d-arrow-anim {
  width: 90px;
}
.one3d-drive-mode-button-text {
  top: 0px;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.4;
  text-align: left;
  position: relative;
  color: rgba(255, 255, 255, 0.6);
}
.one3d-sound-button-contain {
  left: 20px;
}
.one3d-button-test-drive-box {
  right: 160px;
}
.one3d-book-now-box {
  right: 20px;
}
.one3d-features-button-box {
  left: 108px;
}
.one3d-explore-360-button-box {
  left: 232px;
}
.one3d-common-button-box {
  z-index: 9;
  bottom: 20px;
  opacity: 0;
  position: absolute;
  visibility: hidden;
  -webkit-transition: 1s all ease;
  -moz-transition: 1s all ease;
  -ms-transition: 1s all ease;
  -o-transition: 1s all ease;
  transition: 1s all ease;
  -webkit-transform: translateY(300%) scale(1);
  -moz-transform: translateY(300%) scale(1);
  -ms-transform: translateY(300%) scale(1);
  -o-transform: translateY(300%) scale(1);
  transform: translateY(300%) scale(1);
}
.show-common-button {
  opacity: 1;
  visibility: visible;
  -webkit-transform: translateY(0) scale(1);
  -moz-transform: translateY(0) scale(1);
  -ms-transform: translateY(0) scale(1);
  -o-transform: translateY(0) scale(1);
  transform: translateY(0) scale(1);
}
.one3d-button-sound-box {
  display: flex;
  align-items: center;
}
.one3d-common-button {
  background: rgba(16, 15, 17, 0.05);
  border: 1px solid rgba(255, 255, 255, 0.1);
  -webkit-box-shadow: -2px 0px 2px 0px rgba(255, 255, 255, 0.9) inset;
  -moz-box-shadow: -2px 0px 2px 0px rgba(255, 255, 255, 0.9) inset;
  -ms-box-shadow: -2px 0px 2px 0px rgba(255, 255, 255, 0.9) inset;
  -o-box-shadow: -2px 0px 2px 0px rgba(255, 255, 255, 0.9) inset;
  box-shadow: -2px 0px 2px 0px rgba(255, 255, 255, 0.9) inset;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
  letter-spacing: 0.5px;
  color: #fff;
  text-decoration: none;
  padding: 12px 20px;
  border-radius: 40px;
  cursor: pointer;
  -webkit-transition: 0.5s all ease;
  -moz-transition: 0.5s all ease;
  -ms-transition: 0.5s all ease;
  -o-transition: 0.5s all ease;
  transition: 0.5s all ease;
}
.one3d-explore-360-button,
.one3d-book-now-button {
  background: rgba(16, 15, 17, 0.2);
}
.one3d-button-sound-ul {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  margin: 0;
  -webkit-transition: ease 0.2s;
  -moz-transition: ease 0.2s;
  -ms-transition: ease 0.2s;
  -o-transition: ease 0.2s;
  transition: ease 0.2s;
  position: relative;
  height: 24px;
}
.button-sound-bar {
  list-style: none;
  height: 30px;
  width: 2px;
  border-radius: 20px;
  background: #fff;
  margin: 0 1px;
  padding: 0;
  animation-name: wave4;
  animation-duration: 0.3s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  transition: ease 0.2s;
}
.button-sound-bar:nth-child(2) {
  animation-name: wave2;
  animation-delay: 0.2s;
}
.button-sound-bar:nth-child(3) {
  animation-name: wave3;
  animation-delay: 0.23s;
  animation-duration: 0.4s;
}
.button-sound-bar:nth-child(4) {
  animation-name: wave4;
  animation-delay: 0.1s;
  animation-duration: 0.3s;
}
.button-sound-bar:nth-child(5) {
  animation-delay: 0.5s;
}
.button-sound-bar:nth-child(6) {
  animation-name: wave2;
  animation-duration: 0.5s;
}
.button-sound-bar:nth-child(8) {
  animation-name: wave4;
  animation-delay: 0.4s;
  animation-duration: 0.25s;
}
.button-sound-bar:nth-child(9) {
  animation-name: wave3;
  animation-delay: 0.15s;
}
.button-sound-bar:nth-child(10) {
  animation-delay: 0.5s;
}
.button-sound-bar:nth-child(11) {
  animation-name: wave2;
  animation-duration: 0.5s;
}
.button-sound-bar:nth-child(12) {
  animation-name: wave3;
  animation-delay: 0.4s;
  animation-duration: 0.25s;
}
.button-sound-bar:nth-child(13) {
  animation-name: wave4;
  animation-delay: 0.15s;
}
.button-sound-bar:nth-child(14) {
  animation-name: wave4;
  animation-duration: 0.5s;
}
.button-sound-bar:nth-child(15) {
  animation-name: wave4;
  animation-delay: 0.1s;
  animation-duration: 0.5s;
}
@keyframes wave1 {
  from {
    transform: scaleY(1);
  }
  to {
    transform: scaleY(0.5);
  }
}
@keyframes wave2 {
  from {
    transform: scaleY(0.3);
  }
  to {
    transform: scaleY(0.6);
  }
}
@keyframes wave3 {
  from {
    transform: scaleY(0.6);
  }
  to {
    transform: scaleY(0.8);
  }
}
@keyframes wave4 {
  from {
    transform: scaleY(0.2);
  }
  to {
    transform: scaleY(0.5);
  }
}
.one3d-button-muted .button-sound-bar {
  height: 15px;
  -webkit-transform: scaleY(0.5);
  -moz-transform: scaleY(0.5);
  -ms-transform: scaleY(0.5);
  -o-transform: scaleY(0.5);
  transform: scaleY(0.5);
}
@-webkit-keyframes shadowReflection {
  0% {
    -webkit-box-shadow: -2px 0px 2px 0px rgba(255, 255, 255, 0.9) inset;
    -moz-box-shadow: -2px 0px 2px 0px rgba(255, 255, 255, 0.9) inset;
    -ms-box-shadow: -2px 0px 2px 0px rgba(255, 255, 255, 0.9) inset;
    -o-box-shadow: -2px 0px 2px 0px rgba(255, 255, 255, 0.9) inset;
    box-shadow: -2px 0px 2px 0px rgba(255, 255, 255, 0.9) inset;
  }
  25% {
    -webkit-box-shadow: -2px -2px 2px 0px rgba(255, 255, 255, 0.9) inset;
    -moz-box-shadow: -2px -2px 2px 0px rgba(255, 255, 255, 0.9) inset;
    -ms-box-shadow: -2px -2px 2px 0px rgba(255, 255, 255, 0.9) inset;
    -o-box-shadow: -2px -2px 2px 0px rgba(255, 255, 255, 0.9) inset;
    box-shadow: -2px -2px 2px 0px rgba(255, 255, 255, 0.9) inset;
  }
  50% {
    -webkit-box-shadow: 2px 0px 2px 0px rgba(255, 255, 255, 0.9) inset;
    -moz-box-shadow: 2px 0px 2px 0px rgba(255, 255, 255, 0.9) inset;
    -ms-box-shadow: 2px 0px 2px 0px rgba(255, 255, 255, 0.9) inset;
    -o-box-shadow: 2px 0px 2px 0px rgba(255, 255, 255, 0.9) inset;
    box-shadow: 2px 0px 2px 0px rgba(255, 255, 255, 0.9) inset;
  }
  75% {
    -webkit-box-shadow: 2px 2px 2px 0px rgba(255, 255, 255, 0.9) inset;
    -moz-box-shadow: 2px 2px 2px 0px rgba(255, 255, 255, 0.9) inset;
    -ms-box-shadow: 2px 2px 2px 0px rgba(255, 255, 255, 0.9) inset;
    -o-box-shadow: 2px 2px 2px 0px rgba(255, 255, 255, 0.9) inset;
    box-shadow: 2px 2px 2px 0px rgba(255, 255, 255, 0.9) inset;
  }
  100% {
    -webkit-box-shadow: -2px 0px 2px 0px rgba(255, 255, 255, 0.9) inset;
    -moz-box-shadow: -2px 0px 2px 0px rgba(255, 255, 255, 0.9) inset;
    -ms-box-shadow: -2px 0px 2px 0px rgba(255, 255, 255, 0.9) inset;
    -o-box-shadow: -2px 0px 2px 0px rgba(255, 255, 255, 0.9) inset;
    box-shadow: -2px 0px 2px 0px rgba(255, 255, 255, 0.9) inset;
  }
}
@-moz-keyframes shadowReflection {
  0% {
    -webkit-box-shadow: -2px 0px 2px 0px rgba(255, 255, 255, 0.9) inset;
    -moz-box-shadow: -2px 0px 2px 0px rgba(255, 255, 255, 0.9) inset;
    -ms-box-shadow: -2px 0px 2px 0px rgba(255, 255, 255, 0.9) inset;
    -o-box-shadow: -2px 0px 2px 0px rgba(255, 255, 255, 0.9) inset;
    box-shadow: -2px 0px 2px 0px rgba(255, 255, 255, 0.9) inset;
  }
  25% {
    -webkit-box-shadow: -2px -2px 2px 0px rgba(255, 255, 255, 0.9) inset;
    -moz-box-shadow: -2px -2px 2px 0px rgba(255, 255, 255, 0.9) inset;
    -ms-box-shadow: -2px -2px 2px 0px rgba(255, 255, 255, 0.9) inset;
    -o-box-shadow: -2px -2px 2px 0px rgba(255, 255, 255, 0.9) inset;
    box-shadow: -2px -2px 2px 0px rgba(255, 255, 255, 0.9) inset;
  }
  50% {
    -webkit-box-shadow: 2px 0px 2px 0px rgba(255, 255, 255, 0.9) inset;
    -moz-box-shadow: 2px 0px 2px 0px rgba(255, 255, 255, 0.9) inset;
    -ms-box-shadow: 2px 0px 2px 0px rgba(255, 255, 255, 0.9) inset;
    -o-box-shadow: 2px 0px 2px 0px rgba(255, 255, 255, 0.9) inset;
    box-shadow: 2px 0px 2px 0px rgba(255, 255, 255, 0.9) inset;
  }
  75% {
    -webkit-box-shadow: 2px 2px 2px 0px rgba(255, 255, 255, 0.9) inset;
    -moz-box-shadow: 2px 2px 2px 0px rgba(255, 255, 255, 0.9) inset;
    -ms-box-shadow: 2px 2px 2px 0px rgba(255, 255, 255, 0.9) inset;
    -o-box-shadow: 2px 2px 2px 0px rgba(255, 255, 255, 0.9) inset;
    box-shadow: 2px 2px 2px 0px rgba(255, 255, 255, 0.9) inset;
  }
  100% {
    -webkit-box-shadow: -2px 0px 2px 0px rgba(255, 255, 255, 0.9) inset;
    -moz-box-shadow: -2px 0px 2px 0px rgba(255, 255, 255, 0.9) inset;
    -ms-box-shadow: -2px 0px 2px 0px rgba(255, 255, 255, 0.9) inset;
    -o-box-shadow: -2px 0px 2px 0px rgba(255, 255, 255, 0.9) inset;
    box-shadow: -2px 0px 2px 0px rgba(255, 255, 255, 0.9) inset;
  }
}
@keyframes shadowReflection {
  0% {
    -webkit-box-shadow: -2px 0px 2px 0px rgba(255, 255, 255, 0.9) inset;
    -moz-box-shadow: -2px 0px 2px 0px rgba(255, 255, 255, 0.9) inset;
    -ms-box-shadow: -2px 0px 2px 0px rgba(255, 255, 255, 0.9) inset;
    -o-box-shadow: -2px 0px 2px 0px rgba(255, 255, 255, 0.9) inset;
    box-shadow: -2px 0px 2px 0px rgba(255, 255, 255, 0.9) inset;
  }
  25% {
    -webkit-box-shadow: -2px -2px 2px 0px rgba(255, 255, 255, 0.9) inset;
    -moz-box-shadow: -2px -2px 2px 0px rgba(255, 255, 255, 0.9) inset;
    -ms-box-shadow: -2px -2px 2px 0px rgba(255, 255, 255, 0.9) inset;
    -o-box-shadow: -2px -2px 2px 0px rgba(255, 255, 255, 0.9) inset;
    box-shadow: -2px -2px 2px 0px rgba(255, 255, 255, 0.9) inset;
  }
  50% {
    -webkit-box-shadow: 2px 0px 2px 0px rgba(255, 255, 255, 0.9) inset;
    -moz-box-shadow: 2px 0px 2px 0px rgba(255, 255, 255, 0.9) inset;
    -ms-box-shadow: 2px 0px 2px 0px rgba(255, 255, 255, 0.9) inset;
    -o-box-shadow: 2px 0px 2px 0px rgba(255, 255, 255, 0.9) inset;
    box-shadow: 2px 0px 2px 0px rgba(255, 255, 255, 0.9) inset;
  }
  75% {
    -webkit-box-shadow: 2px 2px 2px 0px rgba(255, 255, 255, 0.9) inset;
    -moz-box-shadow: 2px 2px 2px 0px rgba(255, 255, 255, 0.9) inset;
    -ms-box-shadow: 2px 2px 2px 0px rgba(255, 255, 255, 0.9) inset;
    -o-box-shadow: 2px 2px 2px 0px rgba(255, 255, 255, 0.9) inset;
    box-shadow: 2px 2px 2px 0px rgba(255, 255, 255, 0.9) inset;
  }
  100% {
    -webkit-box-shadow: -2px 0px 2px 0px rgba(255, 255, 255, 0.9) inset;
    -moz-box-shadow: -2px 0px 2px 0px rgba(255, 255, 255, 0.9) inset;
    -ms-box-shadow: -2px 0px 2px 0px rgba(255, 255, 255, 0.9) inset;
    -o-box-shadow: -2px 0px 2px 0px rgba(255, 255, 255, 0.9) inset;
    box-shadow: -2px 0px 2px 0px rgba(255, 255, 255, 0.9) inset;
  }
}
.one3d-rhs-menu {
  top: 50%;
  left: auto;
  right: -10px;
  position: absolute;
  -webkit-transition: 0.6s all ease;
  -moz-transition: 0.6s all ease;
  -ms-transition: 0.6s all ease;
  -o-transition: 0.6s all ease;
  transition: 0.6s all ease;
  -webkit-transform: translate(0, -50%) scale(1);
  -moz-transform: translate(0, -50%) scale(1);
  -ms-transform: translate(0, -50%) scale(1);
  -o-transform: translate(0, -50%) scale(1);
  transform: translate(0, -50%) scale(1);
}
.hover-slide-rhs {
  -webkit-transform: translate(-20px, -50%) scale(1);
  -moz-transform: translate(-20px, -50%) scale(1);
  -ms-transform: translate(-20px, -50%) scale(1);
  -o-transform: translate(-20px, -50%) scale(1);
  transform: translate(-20px, -50%) scale(1);
}
.one3d-rhs-menu .arc-text,
.one3d-rhs-menu .arc-text-box {
  -webkit-transform: translateX(-30px);
  -moz-transform: translateX(-30px);
  -ms-transform: translateX(-30px);
  -o-transform: translateX(-30px);
  transform: translateX(-30px);
}
.one3d-lhs-menu {
  top: 50%;
  opacity: 1;
  left: 0px;
  right: auto;
  position: absolute;
  visibility: visible;
  -webkit-transition: 0.6s all ease;
  -moz-transition: 0.6s all ease;
  -ms-transition: 0.6s all ease;
  -o-transition: 0.6s all ease;
  transition: 0.6s all ease;
  -webkit-transform: translate(0, -50%) scale(1);
  -moz-transform: translate(0, -50%) scale(1);
  -ms-transform: translate(0, -50%) scale(1);
  -o-transform: translate(0, -50%) scale(1);
  transform: translate(0, -50%) scale(1);
}
.hide-lhs-menu {
  opacity: 0;
  visibility: hidden;
}
.hover-slide-lhs {
  -webkit-transform: translate(20px, -50%) scale(1);
  -moz-transform: translate(20px, -50%) scale(1);
  -ms-transform: translate(20px, -50%) scale(1);
  -o-transform: translate(20px, -50%) scale(1);
  transform: translate(20px, -50%) scale(1);
}
.one3d-lhs-menu .arc-text,
.one3d-lhs-menu .arc-text-box {
  -webkit-transform: translateX(30px);
  -moz-transform: translateX(30px);
  -ms-transform: translateX(30px);
  -o-transform: translateX(30px);
  transform: translateX(30px);
}
.one3d-color-list {
  left: 50%;
  bottom: 14px;
  position: absolute;
  -webkit-transition: 1s all ease;
  -moz-transition: 1s all ease;
  -ms-transition: 1s all ease;
  -o-transition: 1s all ease;
  transition: 1s all ease;
  -webkit-transform: translate(-50%, 300%) scale(1);
  -moz-transform: translate(-50%, 300%) scale(1);
  -ms-transform: translate(-50%, 300%) scale(1);
  -o-transform: translate(-50%, 300%) scale(1);
  transform: translate(-50%, 300%) scale(1);
}
.show-color-ui {
  opacity: 1;
  visibility: visible;
  -webkit-transform: translate(-50%, 0px) scale(1);
  -moz-transform: translate(-50%, 0px) scale(1);
  -ms-transform: translate(-50%, 0px) scale(1);
  -o-transform: translate(-50%, 0px) scale(1);
  transform: translate(-50%, 0px) scale(1);
}
.one3d-color-swatches-ul {
  margin: 0;
  padding: 0;
  display: flex !important;
  align-items: center;
  list-style-type: none;
  justify-content: center;
}
.one3d-color-swatches-li {
  padding: 0;
  width: 57px;
  height: 57px;
  margin: 0 5px;
  border-radius: 50%;
  user-select: none;
  -webkit-transition: 0.5s all ease;
  -moz-transition: 0.5s all ease;
  -ms-transition: 0.5s all ease;
  -o-transition: 0.5s all ease;
  transition: 0.5s all ease;
}
.one3d-color-swatch-circle {
  margin: 0;
  padding: 0;
  width: 57px;
  height: 57px;
  display: flex;
  background: none;
  align-items: center;
  justify-content: center;
  -webkit-transition: 0.6s all ease;
  -moz-transition: 0.6s all ease;
  -ms-transition: 0.6s all ease;
  -o-transition: 0.6s all ease;
  transition: 0.6s all ease;
  border-radius: 50%;
  border: 2px solid rgba(234, 234, 234, 0);
}
.one3d-color-car-dual-tone {
  width: 36px;
  height: 36px;
  display: block;
  cursor: pointer;
  position: relative;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}
.one3d-color-car-roof {
  width: 36px;
  height: 18px;
  position: relative;
  border-radius: 100px 100px 0 0;
}
.one3d-color-car-body {
  width: 36px;
  height: 18px;
  position: relative;
  border-radius: 0 0 100px 100px;
}
.one3d-color-list .selected .one3d-color-swatch-circle {
  position: relative;
  border: 2px solid rgba(234, 234, 234, 1);
}
.selected {
  pointer-events: none;
  -webkit-transform: translateY(-10px);
  -moz-transform: translateY(-10px);
  -ms-transform: translateY(-10px);
  -o-transform: translateY(-10px);
  transform: translateY(-10px);
}
.one3d-color-swatch-circle::after {
  top: 50%;
  left: 50%;
  content: "";
  width: 15px;
  height: 15px;
  opacity: 0;
  visibility: hidden;
  position: absolute;
  pointer-events: none;
  -webkit-transition: 0.6s all ease;
  -moz-transition: 0.6s all ease;
  -ms-transition: 0.6s all ease;
  -o-transition: 0.6s all ease;
  transition: 0.6s all ease;
  background: url(././assets/images/selected-tick.svg);
  background-position: center;
  background-repeat: no-repeat;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.selected .one3d-color-swatch-circle::after {
  opacity: 1;
  visibility: visible;
}
.one3d-color-name {
  color: #fff;
  font-size: 18px;
  user-select: none;
  white-space: nowrap;
}
.one3d-color-name-display {
  left: 50%;
  bottom: 70px;
  position: absolute;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  transform: translateX(-50%);
}
.arc-svg-lhs path,
.arc-svg-lhs rect,
.arc-svg-rhs path,
.arc-svg-rhs rect {
  opacity: 0;
}
.arc-text {
  pointer-events: none !important;
}
.dot,
.arc-text,
.dot-shadow,
.arc-text-box {
  cursor: pointer;
}
.one3d-variant-name {
  top: -18%;
  left: 50%;
  width: 50%;
  opacity: 0.5;
  position: absolute;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  transform: translateX(-50%);
}
.arc-text-box,
.dot-shadow {
  -webkit-transition: 0.6s all ease;
  -moz-transition: 0.6s all ease;
  -ms-transition: 0.6s all ease;
  -o-transition: 0.6s all ease;
  transition: 0.6s all ease;
}
.active-box,
.active-dot-shadow {
  opacity: 1 !important;
  pointer-events: none !important;
}
.active-dot,
.one3d-variant-name {
  pointer-events: none;
}
.one3d-block-portrait-view {
  top: 0;
  left: 0;
  z-index: 99;
  width: 100%;
  height: 100%;
  position: absolute;
}
.one3d-block-portrait-box {
  top: 0;
  left: 0;
  z-index: 99;
  width: 100%;
  height: 100%;
  display: flex;
  text-align: center;
  position: absolute;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background: -webkit-radial-gradient(
    61.77% 61.77% at 50% 50%,
    #000000 0%,
    #000000 100%
  );
  background: -moz-radial-gradient(
    61.77% 61.77% at 50% 50%,
    #000000 0%,
    #000000 100%
  );
  background: -ms-radial-gradient(
    61.77% 61.77% at 50% 50%,
    #000000 0%,
    #000000 100%
  );
  background: -o-radial-gradient(
    61.77% 61.77% at 50% 50%,
    #000000 0%,
    #000000 100%
  );
  background: radial-gradient(
    61.77% 61.77% at 50% 50%,
    #000000 0%,
    #000000 100%
  );
}
.one3d-block-portrait-text {
  color: #fff;
  font-size: 18px;
  padding: 0 40px;
  line-height: 1.4;
  font-weight: 500;
  text-align: center;
  margin: 10px 0 0;
}
.one3d-block-portrait-icon {
  display: flex;
}
.one3d-block-landscape-view {
  top: 0;
  left: 0;
  z-index: 99;
  width: 100%;
  height: 100%;
  position: absolute;
  .one3d-block-portrait-text {
    margin: 20px 0;
    font-size: 16px;
    font-weight: 400;
    color: #d9d9d9;
  }
  .one3d-block-portrait-big-text {
    margin: 0;
    font-size: 20px;
    font-weight: 600;
    color: rgba(255, 255, 255, 0.6);
  }
}
.one3d-no-internet-connection {
  top: 0;
  left: 0;
  z-index: 99;
  width: 100%;
  height: 100%;
  position: absolute;
}
.one3d-no-internet-box {
  top: 0;
  left: 0;
  z-index: 99;
  width: 100%;
  height: 100%;
  display: flex;
  text-align: center;
  position: absolute;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background: -webkit-radial-gradient(
    61.77% 61.77% at 50% 50%,
    #000000 0%,
    #000000 100%
  );
  background: -moz-radial-gradient(
    61.77% 61.77% at 50% 50%,
    #000000 0%,
    #000000 100%
  );
  background: -ms-radial-gradient(
    61.77% 61.77% at 50% 50%,
    #000000 0%,
    #000000 100%
  );
  background: -o-radial-gradient(
    61.77% 61.77% at 50% 50%,
    #000000 0%,
    #000000 100%
  );
  background: radial-gradient(
    61.77% 61.77% at 50% 50%,
    #000000 0%,
    #000000 100%
  );
}
.one3d-no-internet-icon {
  display: flex;
}
.one3d-no-internet-heading {
  font-size: 20px;
  font-weight: 700;
  line-height: 1.6;
  text-align: center;
  margin: 20px 0 4px;
}
.one3d-no-internet-text {
  margin: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.4;
  text-align: center;
  color: #c4c4c4;
  padding: 0 10px;
}
.one3d-unsupported-browser {
  top: 0;
  left: 0;
  z-index: 99;
  width: 100%;
  height: 100%;
  position: absolute;
}
.one3d-unsupported-browser-box {
  top: 0;
  left: 0;
  z-index: 99;
  width: 100%;
  height: 100%;
  display: flex;
  text-align: center;
  position: absolute;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background: -webkit-radial-gradient(
    61.77% 61.77% at 50% 50%,
    #000000 0%,
    #000000 100%
  );
  background: -moz-radial-gradient(
    61.77% 61.77% at 50% 50%,
    #000000 0%,
    #000000 100%
  );
  background: -ms-radial-gradient(
    61.77% 61.77% at 50% 50%,
    #000000 0%,
    #000000 100%
  );
  background: -o-radial-gradient(
    61.77% 61.77% at 50% 50%,
    #000000 0%,
    #000000 100%
  );
  background: radial-gradient(
    61.77% 61.77% at 50% 50%,
    #000000 0%,
    #000000 100%
  );
}
.one3d-unsupported-browser-icon {
  display: flex;
}
.one3d-unsupported-browser-heading {
  font-size: 20px;
  font-weight: 700;
  line-height: 1.6;
  text-align: center;
  margin: 20px 0 10px;
}
.one3d-unsupported-browser-text {
  font-size: 14px;
  font-weight: 400;
  line-height: 1.4;
  text-align: center;
  color: #c4c4c4;
  margin: 0 0 40px;
  padding: 0 10px;
  span {
    color: #fff;
    display: block;
    font-weight: 600;
  }
}
.one3d-unsupported-browser-logo {
  opacity: 0.5;
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  justify-content: center;
}
.one3d-unsupported-browser-logo-list {
  margin: 10px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.one3d-unsupported-browser-logo-icon {
  width: 50px;
  height: 50px;
}
.one3d-unsupported-browser-logo-name {
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.6;
  text-align: center;
  white-space: nowrap;
}
.one3d-maintenance-in-progress {
  top: 0;
  left: 0;
  z-index: 99;
  width: 100%;
  height: 100%;
  position: absolute;
}
.one3d-maintenance-in-progress-box {
  top: 0;
  left: 0;
  z-index: 99;
  width: 100%;
  height: 100%;
  display: flex;
  text-align: center;
  position: absolute;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background: -webkit-radial-gradient(
    61.77% 61.77% at 50% 50%,
    #000000 0%,
    #000000 100%
  );
  background: -moz-radial-gradient(
    61.77% 61.77% at 50% 50%,
    #000000 0%,
    #000000 100%
  );
  background: -ms-radial-gradient(
    61.77% 61.77% at 50% 50%,
    #000000 0%,
    #000000 100%
  );
  background: -o-radial-gradient(
    61.77% 61.77% at 50% 50%,
    #000000 0%,
    #000000 100%
  );
  background: radial-gradient(
    61.77% 61.77% at 50% 50%,
    #000000 0%,
    #000000 100%
  );
}
.one3d-maintenance-in-progress-icon {
  display: flex;
}
.one3d-maintenance-in-progress-heading {
  font-size: 20px;
  font-weight: 700;
  line-height: 1.6;
  text-align: center;
  margin: 20px 0 4px;
}
.one3d-maintenance-in-progress-text {
  font-size: 14px;
  font-weight: 400;
  line-height: 1.4;
  text-align: center;
  color: #c4c4c4;
  margin: 0;
  padding: 0 10px;
}
.one3d-lhs-menu-mobile,
.one3d-rhs-menu-mobile {
  top: 50%;
  display: none;
  position: absolute;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
}
.one3d-lhs-menu-mobile {
  left: -14px;
}
.one3d-rhs-menu-mobile {
  right: -14px;
}
.one3d-gradient-left {
  left: 0;
  width: 30px;
  bottom: 33px;
  height: 40px;
  position: absolute;
  background: -webkit-linear-gradient(
    90deg,
    rgba(0, 0, 0, 1) 44.5%,
    rgba(0, 0, 0, 0) 100%
  );
  background: -moz-linear-gradient(
    90deg,
    rgba(0, 0, 0, 1) 44.5%,
    rgba(0, 0, 0, 0) 100%
  );
  background: -ms-linear-gradient(
    90deg,
    rgba(0, 0, 0, 1) 44.5%,
    rgba(0, 0, 0, 0) 100%
  );
  background: -o-linear-gradient(
    90deg,
    rgba(0, 0, 0, 1) 44.5%,
    rgba(0, 0, 0, 0) 100%
  );
  background: linear-gradient(
    90deg,
    rgba(0, 0, 0, 1) 44.5%,
    rgba(0, 0, 0, 0) 100%
  );
}
.one3d-gradient-right {
  right: 0;
  width: 30px;
  bottom: 33px;
  height: 40px;
  position: absolute;
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
  background: -webkit-linear-gradient(
    90deg,
    rgba(0, 0, 0, 1) 44.5%,
    rgba(0, 0, 0, 0) 100%
  );
  background: -moz-linear-gradient(
    90deg,
    rgba(0, 0, 0, 1) 44.5%,
    rgba(0, 0, 0, 0) 100%
  );
  background: -ms-linear-gradient(
    90deg,
    rgba(0, 0, 0, 1) 44.5%,
    rgba(0, 0, 0, 0) 100%
  );
  background: -o-linear-gradient(
    90deg,
    rgba(0, 0, 0, 1) 44.5%,
    rgba(0, 0, 0, 0) 100%
  );
  background: linear-gradient(
    90deg,
    rgba(0, 0, 0, 1) 44.5%,
    rgba(0, 0, 0, 0) 100%
  );
}
.one3d-curv-ev-logo {
  position: absolute;
  left: 50%;
  top: 2%;
  pointer-events: none;
  -webkit-transform: translate(-50%, 0);
  -moz-transform: translate(-50%, 0);
  -ms-transform: translate(-50%, 0);
  -o-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
}
.one3d-curv-ev-logo .persona-copy {
  margin: 0;
}
.one3d-curv-ev-animation {
  position: relative;
  text-align: center;
  color: #fff;
  font-weight: 700;
  white-space: nowrap;
  line-height: 1;
  background: 50% 100%/50% 50% no-repeat
    radial-gradient(ellipse at bottom, #fff, transparent, transparent);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  font-size: 100px;
  opacity: 0.45;
}
.one3d-animated-curve-logo {
  -webkit-animation: reveal 1500ms ease-in-out forwards 100ms;
  -moz-animation: reveal 1500ms ease-in-out forwards 100ms;
  -ms-animation: reveal 1500ms ease-in-out forwards 100ms;
  -o-animation: reveal 1500ms ease-in-out forwards 100ms;
  animation: reveal 1500ms ease-in-out forwards 100ms;
}
.one3d-animated-coupe-logo {
  opacity: 1 !important;
  visibility: visible !important;
  -webkit-animation: reveal 3000ms ease-in-out forwards 200ms;
  animation: reveal 3000ms ease-in-out forwards 200ms;
}
@-webkit-keyframes reveal {
  80% {
    letter-spacing: 8px;
  }
  100% {
    background-size: 300% 300%;
  }
}
@-moz-keyframes reveal {
  80% {
    letter-spacing: 8px;
  }
  100% {
    background-size: 300% 300%;
  }
}
@keyframes reveal {
  80% {
    letter-spacing: 8px;
  }
  100% {
    background-size: 300% 300%;
  }
}
@-webkit-keyframes slideUp {
  from {
    opacity: 0;
    -webkit-transform: translateY(200px) scale(0);
    -moz-transform: translateY(200px) scale(0);
    -ms-transform: translateY(200px) scale(0);
    -o-transform: translateY(200px) scale(0);
    transform: translateY(200px) scale(0);
  }
  to {
    opacity: 1;
    -webkit-transform: translateY(0) scale(1);
    -moz-transform: translateY(0) scale(1);
    -ms-transform: translateY(0) scale(1);
    -o-transform: translateY(0) scale(1);
    transform: translateY(0) scale(1);
  }
}
@-moz-keyframes slideUp {
  from {
    opacity: 0;
    -webkit-transform: translateY(200px) scale(0);
    -moz-transform: translateY(200px) scale(0);
    -ms-transform: translateY(200px) scale(0);
    -o-transform: translateY(200px) scale(0);
    transform: translateY(200px) scale(0);
  }
  to {
    opacity: 1;
    -webkit-transform: translateY(0) scale(1);
    -moz-transform: translateY(0) scale(1);
    -ms-transform: translateY(0) scale(1);
    -o-transform: translateY(0) scale(1);
    transform: translateY(0) scale(1);
  }
}
@keyframes slideUp {
  from {
    opacity: 0;
    -webkit-transform: translateY(200px) scale(0);
    -moz-transform: translateY(200px) scale(0);
    -ms-transform: translateY(200px) scale(0);
    -o-transform: translateY(200px) scale(0);
    transform: translateY(200px) scale(0);
  }
  to {
    opacity: 1;
    -webkit-transform: translateY(0) scale(1);
    -moz-transform: translateY(0) scale(1);
    -ms-transform: translateY(0) scale(1);
    -o-transform: translateY(0) scale(1);
    transform: translateY(0) scale(1);
  }
}
.one3d-drive-mode-wheel-air {
  position: relative;
  -webkit-animation: translateX 1s infinite linear;
  -moz-animation: translateX 1s infinite linear;
  -ms-animation: translateX 1s infinite linear;
  -o-animation: translateX 1s infinite linear;
  animation: translateX 1s infinite linear;
}
@-webkit-keyframes translateX {
  from {
    -webkit-transform: translateX(-2px);
    -moz-transform: translateX(-2px);
    -ms-transform: translateX(-2px);
    -o-transform: translateX(-2px);
    transform: translateX(-2px);
  }
  to {
    -webkit-transform: translateX(2px);
    -moz-transform: translateX(2px);
    -ms-transform: translateX(2px);
    -o-transform: translateX(2px);
    transform: translateX(2px);
  }
}
@-moz-keyframes translateX {
  from {
    -webkit-transform: translateX(-2px);
    -moz-transform: translateX(-2px);
    -ms-transform: translateX(-2px);
    -o-transform: translateX(-2px);
    transform: translateX(-2px);
  }
  to {
    -webkit-transform: translateX(2px);
    -moz-transform: translateX(2px);
    -ms-transform: translateX(2px);
    -o-transform: translateX(2px);
    transform: translateX(2px);
  }
}
@keyframes translateX {
  from {
    -webkit-transform: translateX(-2px);
    -moz-transform: translateX(-2px);
    -ms-transform: translateX(-2px);
    -o-transform: translateX(-2px);
    transform: translateX(-2px);
  }
  to {
    -webkit-transform: translateX(2px);
    -moz-transform: translateX(2px);
    -ms-transform: translateX(2px);
    -o-transform: translateX(2px);
    transform: translateX(2px);
  }
}
.one3d-drive-mode-wheel {
  position: absolute;
  left: 21px;
  top: 17px;
  -webkit-animation: rotateInfinite 2s infinite linear;
  -moz-animation: rotateInfinite 2s infinite linear;
  -ms-animation: rotateInfinite 2s infinite linear;
  -o-animation: rotateInfinite 2s infinite linear;
  animation: rotateInfinite 2s infinite linear;
}
@-webkit-keyframes rotateInfinite {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-moz-keyframes rotateInfinite {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes rotateInfinite {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.one3d-close-drive-mode {
  left: 50%;
  bottom: 120px;
  cursor: pointer;
  position: absolute;
  -webkit-transform: translate(-50%, 0);
  -moz-transform: translate(-50%, 0);
  -ms-transform: translate(-50%, 0);
  -o-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
}
.one3d-text-container {
  position: absolute;
  left: 50%;
  top: 6%;
  -webkit-transform: translate(-50%, 0);
  -moz-transform: translate(-50%, 0);
  -ms-transform: translate(-50%, 0);
  -o-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
  pointer-events: none;
  width: 100%;
  text-align: center;
}
.text-animation {
  text-align: center;
  // text-transform: uppercase;
  font-size: 72px;
  color: #fff;
  font-weight: 700;
  white-space: nowrap;
  opacity: 0.45;
  line-height: 1;
}
.text-animation span {
  display: inline-block;
  -webkit-animation: slideLeft 1.5s forwards cubic-bezier(0.075, 0.82, 0.165, 1);
  -moz-animation: slideLeft 1.5s forwards cubic-bezier(0.075, 0.82, 0.165, 1);
  -ms-animation: slideLeft 1.5s forwards cubic-bezier(0.075, 0.82, 0.165, 1);
  -o-animation: slideLeft 1.5s forwards cubic-bezier(0.075, 0.82, 0.165, 1);
  animation: slideLeft 1.5s forwards cubic-bezier(0.075, 0.82, 0.165, 1);
  opacity: 0;
}
@-moz-keyframes slideLeft {
  from {
    opacity: 0;
    -webkit-transform: translateX(200px);
    -moz-transform: translateX(200px);
    -ms-transform: translateX(200px);
    -o-transform: translateX(200px);
    transform: translateX(200px);
  }
  to {
    opacity: 1;
    -webkit-transform: translateX(0px);
    -moz-transform: translateX(0px);
    -ms-transform: translateX(0px);
    -o-transform: translateX(0px);
    transform: translateX(0px);
  }
}
@-webkit-keyframes slideLeft {
  from {
    opacity: 0;
    -webkit-transform: translateX(200px);
    -moz-transform: translateX(200px);
    -ms-transform: translateX(200px);
    -o-transform: translateX(200px);
    transform: translateX(200px);
  }
  to {
    opacity: 1;
    -webkit-transform: translateX(0px);
    -moz-transform: translateX(0px);
    -ms-transform: translateX(0px);
    -o-transform: translateX(0px);
    transform: translateX(0px);
  }
}
@keyframes slideLeft {
  from {
    opacity: 0;
    -webkit-transform: translateX(200px);
    -moz-transform: translateX(200px);
    -ms-transform: translateX(200px);
    -o-transform: translateX(200px);
    transform: translateX(200px);
  }
  to {
    opacity: 1;
    -webkit-transform: translateX(0px);
    -moz-transform: translateX(0px);
    -ms-transform: translateX(0px);
    -o-transform: translateX(0px);
    transform: translateX(0px);
  }
}
.persona-copy {
  font-size: 18px;
  font-weight: 400;
  line-height: 1.5;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgba(255, 255, 255, 0.45);
  margin: 10px 0 0;
  -webkit-transition: 2s all ease;
  -moz-transition: 2s all ease;
  -ms-transition: 2s all ease;
  -o-transition: 2s all ease;
  transition: 2s all ease;
  opacity: 0;
  -webkit-animation: fadeInEffect 2s ease 1 forwards;
  -moz-animation: fadeInEffect 2s ease 1 forwards;
  -ms-animation: fadeInEffect 2s ease 1 forwards;
  -o-animation: fadeInEffect 2s ease 1 forwards;
  animation: fadeInEffect 2s ease 1 forwards;
}
@-webkit-keyframes fadeInEffect {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-moz-keyframes fadeInEffect {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadeInEffect {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.persona-copy-text {
  font-weight: 700;
}
.persona-copy-rectangle {
  width: 18px;
  height: 2px;
  margin: 0 10px;
  display: inline-block;
  vertical-align: middle;
  background-color: rgba(255, 255, 255, 0.45);
}
.persona-copy-secondary {
  display: none;
}
.adas-screen-activated .text-animation {
  opacity: 1;
}
.adas-screen-activated .persona-copy {
  opacity: 1;
  color: rgba(255, 255, 255, 0.45);
}
.adas-screen-activated .persona-copy-secondary {
  display: block;
}
.suv-coupe-text-container {
  top: 0;
  opacity: 0;
  visibility: hidden;
}
.suv-coupe-text-container .text-animation {
  font-weight: 700;
  text-transform: initial;
  font-family: $secondaryFont;
}
.suv-coupe-text-container .coupe-text {
  font-size: 96px;
  font-weight: 300;
  font-family: $secondaryFont;
}
.suv-coupe-copy {
  position: relative;
  font-size: 16px;
  font-weight: 200;
  line-height: 1.5;
  text-align: center;
  display: inline-block;
  align-items: center;
  justify-content: center;
  color: rgba(255, 255, 255, 0.45);
  margin: 10px 0 0;
  -webkit-transition: 2s all ease;
  -moz-transition: 2s all ease;
  -ms-transition: 2s all ease;
  -o-transition: 2s all ease;
  transition: 2s all ease;
}
.suv-coupe-copy::before,
.suv-coupe-copy::after {
  content: "";
  position: absolute;
  left: -40px;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -moz-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  -o-transform: translate(0, -50%);
  transform: translate(0, -50%);
  width: 30px;
  height: 2px;
  background-color: rgba(255, 255, 255, 0.45);
}
.suv-coupe-copy::after {
  left: auto;
  right: -40px;
}
// Tutorials starts here
// Tap and drag to rotate starts here
.one3d-tutorial {
  left: 50%;
  z-index: 99;
  bottom: 30px;
  position: absolute;
  text-align: center;
  pointer-events: none;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: 0.6s all ease;
  -moz-transition: 0.6s all ease;
  -ms-transition: 0.6s all ease;
  -o-transition: 0.6s all ease;
  transition: 0.6s all ease;
  -webkit-transform: translate(-50%, 0);
  -moz-transform: translate(-50%, 0);
  -ms-transform: translate(-50%, 0);
  -o-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
}
.one3d-tutorial-tap-drag-adas-screen {
  top: 30px;
  bottom: auto;
}
.one3d-tutorial-show {
  opacity: 1;
  visibility: visible;
}
.one3d-tap-drag-text {
  font-size: 20px;
  font-weight: 400;
  line-height: 1.6;
}
.one3d-tap-drag-strong {
  font-weight: 600;
}
.one3d-tutorial-tap-drag-circle,
.one3d-tutorial-tap-drag-circle-border {
  -webkit-animation: oscillateX 4s infinite linear;
  -moz-animation: oscillateX 4s infinite linear;
  -ms-animation: oscillateX 4s infinite linear;
  -o-animation: oscillateX 4s infinite linear;
  animation: oscillateX 4s infinite linear;
}
@-webkit-keyframes oscillateX {
  0% {
    -webkit-transform: translateX(0px);
    -moz-transform: translateX(0px);
    -ms-transform: translateX(0px);
    -o-transform: translateX(0px);
    transform: translateX(0px);
  }
  50% {
    -webkit-transform: translateX(calc(100% - 50px));
    -moz-transform: translateX(calc(100% - 50px));
    -ms-transform: translateX(calc(100% - 50px));
    -o-transform: translateX(calc(100% - 50px));
    transform: translateX(calc(100% - 50px));
  }
  100% {
    -webkit-transform: translateX(0px);
    -moz-transform: translateX(0px);
    -ms-transform: translateX(0px);
    -o-transform: translateX(0px);
    transform: translateX(0px);
  }
}
@-moz-keyframes oscillateX {
  0% {
    -webkit-transform: translateX(0px);
    -moz-transform: translateX(0px);
    -ms-transform: translateX(0px);
    -o-transform: translateX(0px);
    transform: translateX(0px);
  }
  50% {
    -webkit-transform: translateX(calc(100% - 50px));
    -moz-transform: translateX(calc(100% - 50px));
    -ms-transform: translateX(calc(100% - 50px));
    -o-transform: translateX(calc(100% - 50px));
    transform: translateX(calc(100% - 50px));
  }
  100% {
    -webkit-transform: translateX(0px);
    -moz-transform: translateX(0px);
    -ms-transform: translateX(0px);
    -o-transform: translateX(0px);
    transform: translateX(0px);
  }
}
@keyframes oscillateX {
  0% {
    -webkit-transform: translateX(0px);
    -moz-transform: translateX(0px);
    -ms-transform: translateX(0px);
    -o-transform: translateX(0px);
    transform: translateX(0px);
  }
  50% {
    -webkit-transform: translateX(calc(100% - 50px));
    -moz-transform: translateX(calc(100% - 50px));
    -ms-transform: translateX(calc(100% - 50px));
    -o-transform: translateX(calc(100% - 50px));
    transform: translateX(calc(100% - 50px));
  }
  100% {
    -webkit-transform: translateX(0px);
    -moz-transform: translateX(0px);
    -ms-transform: translateX(0px);
    -o-transform: translateX(0px);
    transform: translateX(0px);
  }
}
// Tap and drag to rotate ends here
// Pinch or scroll to zoom in & out starts here
.one3d-pinch-scroll-up-arrow {
  -webkit-animation: oscillateUpY 2s infinite linear;
  -moz-animation: oscillateUpY 2s infinite linear;
  -ms-animation: oscillateUpY 2s infinite linear;
  -o-animation: oscillateUpY 2s infinite linear;
  animation: oscillateUpY 2s infinite linear;
}
@-webkit-keyframes oscillateUpY {
  0% {
    -webkit-transform: translateY(0px);
    -moz-transform: translateY(0px);
    -ms-transform: translateY(0px);
    -o-transform: translateY(0px);
    transform: translateY(0px);
  }
  50% {
    -webkit-transform: translateY(14px);
    -moz-transform: translateY(14px);
    -ms-transform: translateY(14px);
    -o-transform: translateY(14px);
    transform: translateY(14px);
  }
  100% {
    -webkit-transform: translateY(0px);
    -moz-transform: translateY(0px);
    -ms-transform: translateY(0px);
    -o-transform: translateY(0px);
    transform: translateY(0px);
  }
}
@-moz-keyframes oscillateUpY {
  0% {
    -webkit-transform: translateY(0px);
    -moz-transform: translateY(0px);
    -ms-transform: translateY(0px);
    -o-transform: translateY(0px);
    transform: translateY(0px);
  }
  50% {
    -webkit-transform: translateY(14px);
    -moz-transform: translateY(14px);
    -ms-transform: translateY(14px);
    -o-transform: translateY(14px);
    transform: translateY(14px);
  }
  100% {
    -webkit-transform: translateY(0px);
    -moz-transform: translateY(0px);
    -ms-transform: translateY(0px);
    -o-transform: translateY(0px);
    transform: translateY(0px);
  }
}
@keyframes oscillateUpY {
  0% {
    -webkit-transform: translateY(0px);
    -moz-transform: translateY(0px);
    -ms-transform: translateY(0px);
    -o-transform: translateY(0px);
    transform: translateY(0px);
  }
  50% {
    -webkit-transform: translateY(14px);
    -moz-transform: translateY(14px);
    -ms-transform: translateY(14px);
    -o-transform: translateY(14px);
    transform: translateY(14px);
  }
  100% {
    -webkit-transform: translateY(0px);
    -moz-transform: translateY(0px);
    -ms-transform: translateY(0px);
    -o-transform: translateY(0px);
    transform: translateY(0px);
  }
}
.one3d-pinch-scroll-down-arrow {
  -webkit-animation: oscillateDownY 2s infinite linear;
  -moz-animation: oscillateDownY 2s infinite linear;
  -ms-animation: oscillateDownY 2s infinite linear;
  -o-animation: oscillateDownY 2s infinite linear;
  animation: oscillateDownY 2s infinite linear;
}
@-webkit-keyframes oscillateDownY {
  0% {
    -webkit-transform: translateY(0px);
    -moz-transform: translateY(0px);
    -ms-transform: translateY(0px);
    -o-transform: translateY(0px);
    transform: translateY(0px);
  }
  50% {
    -webkit-transform: translateY(-14px);
    -moz-transform: translateY(-14px);
    -ms-transform: translateY(-14px);
    -o-transform: translateY(-14px);
    transform: translateY(-14px);
  }
  100% {
    -webkit-transform: translateY(0px);
    -moz-transform: translateY(0px);
    -ms-transform: translateY(0px);
    -o-transform: translateY(0px);
    transform: translateY(0px);
  }
}
@-moz-keyframes oscillateDownY {
  0% {
    -webkit-transform: translateY(0px);
    -moz-transform: translateY(0px);
    -ms-transform: translateY(0px);
    -o-transform: translateY(0px);
    transform: translateY(0px);
  }
  50% {
    -webkit-transform: translateY(-14px);
    -moz-transform: translateY(-14px);
    -ms-transform: translateY(-14px);
    -o-transform: translateY(-14px);
    transform: translateY(-14px);
  }
  100% {
    -webkit-transform: translateY(0px);
    -moz-transform: translateY(0px);
    -ms-transform: translateY(0px);
    -o-transform: translateY(0px);
    transform: translateY(0px);
  }
}
@keyframes oscillateDownY {
  0% {
    -webkit-transform: translateY(0px);
    -moz-transform: translateY(0px);
    -ms-transform: translateY(0px);
    -o-transform: translateY(0px);
    transform: translateY(0px);
  }
  50% {
    -webkit-transform: translateY(-14px);
    -moz-transform: translateY(-14px);
    -ms-transform: translateY(-14px);
    -o-transform: translateY(-14px);
    transform: translateY(-14px);
  }
  100% {
    -webkit-transform: translateY(0px);
    -moz-transform: translateY(0px);
    -ms-transform: translateY(0px);
    -o-transform: translateY(0px);
    transform: translateY(0px);
  }
}
// Pinch or scroll to zoom in & out ends here
// Tap and hold to drive your Curvv.ev starts here
.one3d-tap-hold-icon {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}
.one3d-tap-hold-circle {
  z-index: 1;
  position: relative;
}
.one3d-tap-hold-circle {
  -webkit-animation: oscillatePulseReverse 2s infinite linear;
  -moz-animation: oscillatePulseReverse 2s infinite linear;
  -ms-animation: oscillatePulseReverse 2s infinite linear;
  -o-animation: oscillatePulseReverse 2s infinite linear;
  animation: oscillatePulseReverse 2s infinite linear;
}
@-webkit-keyframes oscillatePulseReverse {
  0% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
  50% {
    -webkit-transform: scale(0.5);
    -moz-transform: scale(0.5);
    -ms-transform: scale(0.5);
    -o-transform: scale(0.5);
    transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
}
@-moz-keyframes oscillatePulseReverse {
  0% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
  50% {
    -webkit-transform: scale(0.5);
    -moz-transform: scale(0.5);
    -ms-transform: scale(0.5);
    -o-transform: scale(0.5);
    transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes oscillatePulseReverse {
  0% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
  50% {
    -webkit-transform: scale(0.5);
    -moz-transform: scale(0.5);
    -ms-transform: scale(0.5);
    -o-transform: scale(0.5);
    transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
}
.one3d-tap-hold-shadow {
  top: 50%;
  left: 50%;
  z-index: 1;
  position: absolute;
  -webkit-transform: translate(-50%, -50%) scale(1);
  -moz-transform: translate(-50%, -50%) scale(1);
  -ms-transform: translate(-50%, -50%) scale(1);
  -o-transform: translate(-50%, -50%) scale(1);
  transform: translate(-50%, -50%) scale(1);
  animation: oscillatePulse 2s infinite linear;
}
// Tap and hold to drive your Curvv.ev ends here
@-webkit-keyframes oscillatePulse {
  0% {
    -webkit-transform: translate(-50%, -50%) scale(0.5);
    -moz-transform: translate(-50%, -50%) scale(0.5);
    -ms-transform: translate(-50%, -50%) scale(0.5);
    -o-transform: translate(-50%, -50%) scale(0.5);
    transform: translate(-50%, -50%) scale(0.5);
  }
  50% {
    -webkit-transform: translate(-50%, -50%) scale(1);
    -moz-transform: translate(-50%, -50%) scale(1);
    -ms-transform: translate(-50%, -50%) scale(1);
    -o-transform: translate(-50%, -50%) scale(1);
    transform: translate(-50%, -50%) scale(1);
  }
  100% {
    -webkit-transform: translate(-50%, -50%) scale(0.5);
    -moz-transform: translate(-50%, -50%) scale(0.5);
    -ms-transform: translate(-50%, -50%) scale(0.5);
    -o-transform: translate(-50%, -50%) scale(0.5);
    transform: translate(-50%, -50%) scale(0.5);
  }
}
@-moz-keyframes oscillatePulse {
  0% {
    -webkit-transform: translate(-50%, -50%) scale(0.5);
    -moz-transform: translate(-50%, -50%) scale(0.5);
    -ms-transform: translate(-50%, -50%) scale(0.5);
    -o-transform: translate(-50%, -50%) scale(0.5);
    transform: translate(-50%, -50%) scale(0.5);
  }
  50% {
    -webkit-transform: translate(-50%, -50%) scale(1);
    -moz-transform: translate(-50%, -50%) scale(1);
    -ms-transform: translate(-50%, -50%) scale(1);
    -o-transform: translate(-50%, -50%) scale(1);
    transform: translate(-50%, -50%) scale(1);
  }
  100% {
    -webkit-transform: translate(-50%, -50%) scale(0.5);
    -moz-transform: translate(-50%, -50%) scale(0.5);
    -ms-transform: translate(-50%, -50%) scale(0.5);
    -o-transform: translate(-50%, -50%) scale(0.5);
    transform: translate(-50%, -50%) scale(0.5);
  }
}
@keyframes oscillatePulse {
  0% {
    -webkit-transform: translate(-50%, -50%) scale(0.5);
    -moz-transform: translate(-50%, -50%) scale(0.5);
    -ms-transform: translate(-50%, -50%) scale(0.5);
    -o-transform: translate(-50%, -50%) scale(0.5);
    transform: translate(-50%, -50%) scale(0.5);
  }
  50% {
    -webkit-transform: translate(-50%, -50%) scale(1);
    -moz-transform: translate(-50%, -50%) scale(1);
    -ms-transform: translate(-50%, -50%) scale(1);
    -o-transform: translate(-50%, -50%) scale(1);
    transform: translate(-50%, -50%) scale(1);
  }
  100% {
    -webkit-transform: translate(-50%, -50%) scale(0.5);
    -moz-transform: translate(-50%, -50%) scale(0.5);
    -ms-transform: translate(-50%, -50%) scale(0.5);
    -o-transform: translate(-50%, -50%) scale(0.5);
    transform: translate(-50%, -50%) scale(0.5);
  }
}
// Tutorials ends here
.d-none {
  display: none !important;
}
.one3d-lhs-menu-hide {
  left: -200% !important;
}
.one3d-rhs-menu-hide {
  right: -200% !important;
}
.one3d-features-container {
  top: 50%;
  left: 5vw;
  opacity: 0;
  width: 320px;
  visibility: hidden;
  text-align: center;
  position: absolute;
  pointer-events: none;
  -webkit-transform: translate(0, -50%) scale(1);
  -moz-transform: translate(0, -50%) scale(1);
  -ms-transform: translate(0, -50%) scale(1);
  -o-transform: translate(0, -50%) scale(1);
  transform: translate(0, -50%) scale(1);
  -webkit-transition: 2s all ease;
  -moz-transition: 2s all ease;
  -ms-transition: 2s all ease;
  -o-transition: 2s all ease;
  transition: 2s all ease;
}
.one3d-features-heading {
  width: 100%;
  font-size: 20px;
  font-weight: 400;
  line-height: 1.5;
  text-align: center;
  position: absolute;
  top: -30px;
  color: rgba(255, 255, 255, 0.6);
}
.one3d-features-content-container {
  width: 100%;
  display: flex;
  overflow: hidden;
  margin: 10px 0 0;
  padding: 36px 10px;
  position: relative;
  border-radius: 8px;
  align-items: center;
  align-content: center;
  justify-content: center;
  background-size: 100% 100%;
  backdrop-filter: blur(8px);
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(assets/images/one3d-features-box-border.svg);
}
.one3d-features-content-ul {
  list-style-type: none;
}
.one3d-features-content-li {
  opacity: 0;
  z-index: 2;
  width: 100%;
  max-height: 0;
  font-size: 20px;
  font-weight: 500;
  line-height: 1.6;
  overflow: hidden;
  visibility: hidden;
  text-align: center;
  transition: all 1s ease;
}
.one3d-features-content-li + .one3d-features-content-li {
  opacity: 0;
  max-height: 0;
  visibility: hidden;
}
.one3d-features-content-li.active {
  opacity: 1;
  visibility: visible;
  max-height: initial;
}
.one3d-font-weight-700 {
  font-weight: 700 !important;
}
.adas-screen-activated .text-animation {
  position: relative;
  display: inline-block;
}
.adas-screen-activated .text-animation::after {
  top: 0;
  content: "";
  width: 12px;
  right: -13px;
  height: 12px;
  position: absolute;
  background-image: url(data:image/svg+xml,%3Csvg%20width%3D%2213%22%20height%3D%2213%22%20viewBox%3D%220%200%2013%2013%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M0.839667%2013V11.5921L4.82306%200.689244H7.99948L12.0172%2011.6436V13H0.839667ZM3.82721%2010.8194H9.0125L7.17533%205.05038C7.1181%204.85578%207.03797%204.60968%206.93495%204.31207C6.84338%204.01446%206.74608%203.71113%206.64306%203.40207C6.55149%203.09302%206.47709%202.82402%206.41986%202.59509C6.36262%202.82402%206.2825%203.11019%206.17948%203.45358C6.07646%203.78553%205.97916%204.10604%205.88759%204.41509C5.79602%204.7127%205.72734%204.92446%205.68155%205.05038L3.82721%2010.8194Z%22%20fill%3D%22white%22%2F%3E%3C%2Fsvg%3E);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}
.one3d-disclaimer {
  left: 0;
  opacity: 0;
  bottom: 2px;
  width: calc(100% - 6px);
  padding: 0 6px;
  font-size: 10px;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  color: #ffffff;
  visibility: hidden;
  position: absolute;
  text-align: center;
  pointer-events: none;
  -webkit-transition: 0.6s all ease;
  -moz-transition: 0.6s all ease;
  -ms-transition: 0.6s all ease;
  -o-transition: 0.6s all ease;
  transition: 0.6s all ease;
  overflow: hidden;
  white-space: nowrap;
}
.show-disclaimer {
  opacity: 1;
  visibility: visible;
}
// For Desktop Common Screens
@media screen and (min-width: 1200px) {
  .shadow-reflection:hover {
    -webkit-animation: shadowReflection 1s linear 0s infinite normal;
    -moz-animation: shadowReflection 1s linear 0s infinite normal;
    -ms-animation: shadowReflection 1s linear 0s infinite normal;
    -o-animation: shadowReflection 1s linear 0s infinite normal;
    animation: shadowReflection 1s linear 0s infinite normal;
  }
  .one3d-rhs-menu:hover {
    -webkit-transform: translate(-20px, -50%) scale(1);
    -moz-transform: translate(-20px, -50%) scale(1);
    -ms-transform: translate(-20px, -50%) scale(1);
    -o-transform: translate(-20px, -50%) scale(1);
    transform: translate(-20px, -50%) scale(1);
  }
  .one3d-lhs-menu:hover {
    -webkit-transform: translate(20px, -50%) scale(1);
    -moz-transform: translate(20px, -50%) scale(1);
    -ms-transform: translate(20px, -50%) scale(1);
    -o-transform: translate(20px, -50%) scale(1);
    transform: translate(20px, -50%) scale(1);
  }
  .one3d-color-swatches-li:hover {
    transform: translateY(-10px);
  }
}
// Resolution 1920x1080
// Scale 125%
// Zoom out : 90%  === Absolute Width : 1706
@media only screen and (min-width: 1600px) and (max-width: 1800px) {
  .one3d-rhs-menu {
    right: -10px;
    -webkit-transform: translate(0, -50%) scale(1);
    -moz-transform: translate(0, -50%) scale(1);
    -ms-transform: translate(0, -50%) scale(1);
    -o-transform: translate(0, -50%) scale(1);
    transform: translate(0, -50%) scale(1);
  }
  .one3d-lhs-menu {
    left: 0;
    -webkit-transform: translate(0, -50%) scale(1);
    -moz-transform: translate(0, -50%) scale(1);
    -ms-transform: translate(0, -50%) scale(1);
    -o-transform: translate(0, -50%) scale(1);
    transform: translate(0, -50%) scale(1);
  }
  .one3d-rhs-menu:hover,
  .hover-slide-rhs {
    -webkit-transform: translate(-20px, -50%) scale(1);
    -moz-transform: translate(-20px, -50%) scale(1);
    -ms-transform: translate(-20px, -50%) scale(1);
    -o-transform: translate(-20px, -50%) scale(1);
    transform: translate(-20px, -50%) scale(1);
  }
  .one3d-lhs-menu:hover,
  .hover-slide-lhs {
    -webkit-transform: translate(20px, -50%) scale(1);
    -moz-transform: translate(20px, -50%) scale(1);
    -ms-transform: translate(20px, -50%) scale(1);
    -o-transform: translate(20px, -50%) scale(1);
    transform: translate(20px, -50%) scale(1);
  }
  .one3d-tutorial {
    bottom: 50px;
    -webkit-transform: translate(-50%, 0) scale(1.3);
    -moz-transform: translate(-50%, 0) scale(1.3);
    -ms-transform: translate(-50%, 0) scale(1.3);
    -o-transform: translate(-50%, 0) scale(1.3);
    transform: translate(-50%, 0) scale(1.3);
  }
  .one3d-tutorial-tap-drag-adas-screen {
    top: 50px;
    bottom: auto;
  }
  .one3d-tap-to-unmute-button {
    bottom: 50px;
    -webkit-transform: translate(-50%, 0) scale(1.3);
    -moz-transform: translate(-50%, 0) scale(1.3);
    -ms-transform: translate(-50%, 0) scale(1.3);
    -o-transform: translate(-50%, 0) scale(1.3);
    transform: translate(-50%, 0) scale(1.3);
  }
  .one3d-drive-mode-button-box,
  .one3d-park-mode-button-box {
    bottom: 50px;
    -webkit-transform: translate(-50%, 0) scale(1.1);
    -moz-transform: translate(-50%, 0) scale(1.1);
    -ms-transform: translate(-50%, 0) scale(1.1);
    -o-transform: translate(-50%, 0) scale(1.1);
    transform: translate(-50%, 0) scale(1.1);
  }
  .persona-copy {
    font-size: 22px;
  }
}
// Zoom out : 80% === Absolute Width : 1920
@media only screen and (min-width: 1800px) and (max-width: 2000px) {
  .one3d-rhs-menu {
    right: 0;
    -webkit-transform: translate(0, -50%) scale(1.1);
    -moz-transform: translate(0, -50%) scale(1.1);
    -ms-transform: translate(0, -50%) scale(1.1);
    -o-transform: translate(0, -50%) scale(1.1);
    transform: translate(0, -50%) scale(1.1);
  }
  .one3d-lhs-menu {
    left: 16px;
    -webkit-transform: translate(0, -50%) scale(1.1);
    -moz-transform: translate(0, -50%) scale(1.1);
    -ms-transform: translate(0, -50%) scale(1.1);
    -o-transform: translate(0, -50%) scale(1.1);
    transform: translate(0, -50%) scale(1.1);
  }
  .one3d-rhs-menu:hover,
  .hover-slide-rhs {
    -webkit-transform: translate(-20px, -50%) scale(1.1);
    -moz-transform: translate(-20px, -50%) scale(1.1);
    -ms-transform: translate(-20px, -50%) scale(1.1);
    -o-transform: translate(-20px, -50%) scale(1.1);
    transform: translate(-20px, -50%) scale(1.1);
  }
  .one3d-lhs-menu:hover,
  .hover-slide-lhs {
    -webkit-transform: translate(20px, -50%) scale(1.1);
    -moz-transform: translate(20px, -50%) scale(1.1);
    -ms-transform: translate(20px, -50%) scale(1.1);
    -o-transform: translate(20px, -50%) scale(1.1);
    transform: translate(20px, -50%) scale(1.1);
  }
  .one3d-brand-logo svg {
    width: 140px;
    height: 60px;
  }
  .one3d-sound-button-contain {
    left: 40px;
  }
  .one3d-book-now-box {
    right: 40px;
  }
  .one3d-button-test-drive-box {
    right: 210px;
  }
  .one3d-features-button-box {
    left: 156px;
  }
  .one3d-explore-360-button-box {
    left: 312px;
  }
  .one3d-common-button-box {
    bottom: 30px;
    -webkit-transform: translateY(400%) scale(1.2);
    -moz-transform: translateY(400%) scale(1.2);
    -ms-transform: translateY(400%) scale(1.2);
    -o-transform: translateY(400%) scale(1.2);
    transform: translateY(400%) scale(1.2);
  }
  .show-common-button {
    -webkit-transform: translateY(0) scale(1.2);
    -moz-transform: translateY(0) scale(1.2);
    -ms-transform: translateY(0) scale(1.2);
    -o-transform: translateY(0) scale(1.2);
    transform: translateY(0) scale(1.2);
  }
  .one3d-color-list {
    bottom: 10px;
    -webkit-transform: translate(-50%, 300%) scale(1.2);
    -moz-transform: translate(-50%, 300%) scale(1.2);
    -ms-transform: translate(-50%, 300%) scale(1.2);
    -o-transform: translate(-50%, 300%) scale(1.2);
    transform: translate(-50%, 300%) scale(1.2);
  }
  .show-color-ui {
    -webkit-transform: translate(-50%, 0%) scale(1.2);
    -moz-transform: translate(-50%, 0%) scale(1.2);
    -ms-transform: translate(-50%, 0%) scale(1.2);
    -o-transform: translate(-50%, 0%) scale(1.2);
    transform: translate(-50%, 0%) scale(1.2);
  }
  .one3d-main-loader-percentage {
    -webkit-transform: translate(-50%, -50%) scale(1.4);
    -moz-transform: translate(-50%, -50%) scale(1.4);
    -ms-transform: translate(-50%, -50%) scale(1.4);
    -o-transform: translate(-50%, -50%) scale(1.4);
    transform: translate(-50%, -50%) scale(1.4);
  }
  .one3d-tutorial {
    bottom: 60px;
    -webkit-transform: translate(-50%, 0) scale(1.2);
    -moz-transform: translate(-50%, 0) scale(1.2);
    -ms-transform: translate(-50%, 0) scale(1.2);
    -o-transform: translate(-50%, 0) scale(1.2);
    transform: translate(-50%, 0) scale(1.2);
  }
  .one3d-tutorial-tap-drag-adas-screen {
    top: 60px;
    bottom: auto;
  }
  .one3d-tap-to-unmute-button {
    bottom: 50px;
    -webkit-transform: translate(-50%, 0) scale(1.3);
    -moz-transform: translate(-50%, 0) scale(1.3);
    -ms-transform: translate(-50%, 0) scale(1.3);
    -o-transform: translate(-50%, 0) scale(1.3);
    transform: translate(-50%, 0) scale(1.3);
  }
  .one3d-drive-mode-button-box,
  .one3d-park-mode-button-box {
    bottom: 50px;
    -webkit-transform: translate(-50%, 0) scale(1.1);
    -moz-transform: translate(-50%, 0) scale(1.1);
    -ms-transform: translate(-50%, 0) scale(1.1);
    -o-transform: translate(-50%, 0) scale(1.1);
    transform: translate(-50%, 0) scale(1.1);
  }
  .persona-copy,
  .suv-coupe-copy {
    font-size: 26px;
  }
  .one3d-curv-ev-animation {
    font-size: 120px;
  }
  .text-animation {
    font-size: 84px;
  }
  .suv-coupe-text-container .coupe-text {
    font-size: 116px;
  }
  .one3d-features-container {
    -webkit-transform: translate(0, -50%) scale(1.2);
    -moz-transform: translate(0, -50%) scale(1.2);
    -ms-transform: translate(0, -50%) scale(1.2);
    -o-transform: translate(0, -50%) scale(1.2);
    transform: translate(0, -50%) scale(1.2);
  }
}
// Zoom out : 75%   === Absolute Width : 2048
// Zoom out : 67%   === Absolute Width : 2304
@media only screen and (min-width: 2000px) and (max-width: 3000px) {
  .one3d-rhs-menu {
    right: 0;
    -webkit-transform: translate(0, -50%) scale(1.2);
    -moz-transform: translate(0, -50%) scale(1.2);
    -ms-transform: translate(0, -50%) scale(1.2);
    -o-transform: translate(0, -50%) scale(1.2);
    transform: translate(0, -50%) scale(1.2);
  }
  .one3d-lhs-menu {
    left: 16px;
    -webkit-transform: translate(0, -50%) scale(1.2);
    -moz-transform: translate(0, -50%) scale(1.2);
    -ms-transform: translate(0, -50%) scale(1.2);
    -o-transform: translate(0, -50%) scale(1.2);
    transform: translate(0, -50%) scale(1.2);
  }
  .one3d-rhs-menu:hover,
  .hover-slide-rhs {
    -webkit-transform: translate(-20px, -50%) scale(1.2);
    -moz-transform: translate(-20px, -50%) scale(1.2);
    -ms-transform: translate(-20px, -50%) scale(1.2);
    -o-transform: translate(-20px, -50%) scale(1.2);
    transform: translate(-20px, -50%) scale(1.2);
  }
  .one3d-lhs-menu:hover,
  .hover-slide-lhs {
    -webkit-transform: translate(20px, -50%) scale(1.2);
    -moz-transform: translate(20px, -50%) scale(1.2);
    -ms-transform: translate(20px, -50%) scale(1.2);
    -o-transform: translate(20px, -50%) scale(1.2);
    transform: translate(20px, -50%) scale(1.2);
  }
  .one3d-brand-logo svg {
    width: 140px;
    height: 60px;
  }
  .one3d-sound-button-contain {
    left: 40px;
  }
  .one3d-features-button-box {
    left: 156px;
  }
  .one3d-book-now-box {
    right: 40px;
  }
  .one3d-button-test-drive-box {
    right: 210px;
  }
  .one3d-explore-360-button-box {
    left: 312px;
  }
  .one3d-common-button-box {
    bottom: 30px;
    -webkit-transform: translateY(400%) scale(1.2);
    -moz-transform: translateY(400%) scale(1.2);
    -ms-transform: translateY(400%) scale(1.2);
    -o-transform: translateY(400%) scale(1.2);
    transform: translateY(400%) scale(1.2);
  }
  .show-common-button {
    -webkit-transform: translateY(0) scale(1.2);
    -moz-transform: translateY(0) scale(1.2);
    -ms-transform: translateY(0) scale(1.2);
    -o-transform: translateY(0) scale(1.2);
    transform: translateY(0) scale(1.2);
  }
  .one3d-color-list {
    bottom: 10px;
    -webkit-transform: translate(-50%, 300%) scale(1.2);
    -moz-transform: translate(-50%, 300%) scale(1.2);
    -ms-transform: translate(-50%, 300%) scale(1.2);
    -o-transform: translate(-50%, 300%) scale(1.2);
    transform: translate(-50%, 300%) scale(1.2);
  }
  .show-color-ui {
    -webkit-transform: translate(-50%, 0%) scale(1.2);
    -moz-transform: translate(-50%, 0%) scale(1.2);
    -ms-transform: translate(-50%, 0%) scale(1.2);
    -o-transform: translate(-50%, 0%) scale(1.2);
    transform: translate(-50%, 0%) scale(1.2);
  }
  .one3d-main-loader-percentage {
    -webkit-transform: translate(-50%, -50%) scale(1.4);
    -moz-transform: translate(-50%, -50%) scale(1.4);
    -ms-transform: translate(-50%, -50%) scale(1.4);
    -o-transform: translate(-50%, -50%) scale(1.4);
    transform: translate(-50%, -50%) scale(1.4);
  }
  .one3d-tutorial {
    bottom: 100px;
    -webkit-transform: translate(-50%, 0) scale(1.3);
    -moz-transform: translate(-50%, 0) scale(1.3);
    -ms-transform: translate(-50%, 0) scale(1.3);
    -o-transform: translate(-50%, 0) scale(1.3);
    transform: translate(-50%, 0) scale(1.3);
  }
  .one3d-tutorial-tap-drag-adas-screen {
    top: 100px;
    bottom: auto;
  }
  .one3d-tap-to-unmute-button {
    bottom: 70px;
    -webkit-transform: translate(-50%, 0) scale(1.5);
    -moz-transform: translate(-50%, 0) scale(1.5);
    -ms-transform: translate(-50%, 0) scale(1.5);
    -o-transform: translate(-50%, 0) scale(1.5);
    transform: translate(-50%, 0) scale(1.5);
  }
  .one3d-drive-mode-button-box,
  .one3d-park-mode-button-box {
    bottom: 70px;
    -webkit-transform: translate(-50%, 0) scale(1.3);
    -moz-transform: translate(-50%, 0) scale(1.3);
    -ms-transform: translate(-50%, 0) scale(1.3);
    -o-transform: translate(-50%, 0) scale(1.3);
    transform: translate(-50%, 0) scale(1.3);
  }
  .persona-copy,
  .suv-coupe-copy {
    font-size: 26px;
  }
  .one3d-curv-ev-animation {
    font-size: 120px;
  }
  .text-animation {
    font-size: 84px;
  }
  .suv-coupe-text-container .coupe-text {
    font-size: 116px;
  }
  .one3d-features-container {
    -webkit-transform: translate(0, -50%) scale(1.3);
    -moz-transform: translate(0, -50%) scale(1.3);
    -ms-transform: translate(0, -50%) scale(1.3);
    -o-transform: translate(0, -50%) scale(1.3);
    transform: translate(0, -50%) scale(1.3);
  }
}
// Zoom out : 50%   === Absolute Width : 3072
@media only screen and (min-width: 3000px) and (max-width: 4000px) {
  .one3d-rhs-menu {
    right: 40px;
    -webkit-transform: translate(0, -50%) scale(1.2);
    -moz-transform: translate(0, -50%) scale(1.2);
    -ms-transform: translate(0, -50%) scale(1.2);
    -o-transform: translate(0, -50%) scale(1.2);
    transform: translate(0, -50%) scale(1.2);
  }
  .one3d-lhs-menu {
    left: 60px;
    -webkit-transform: translate(0, -50%) scale(1.2);
    -moz-transform: translate(0, -50%) scale(1.2);
    -ms-transform: translate(0, -50%) scale(1.2);
    -o-transform: translate(0, -50%) scale(1.2);
    transform: translate(0, -50%) scale(1.2);
  }
  .one3d-rhs-menu:hover,
  .hover-slide-rhs {
    -webkit-transform: translate(-20px, -50%) scale(1.2);
    -moz-transform: translate(-20px, -50%) scale(1.2);
    -ms-transform: translate(-20px, -50%) scale(1.2);
    -o-transform: translate(-20px, -50%) scale(1.2);
    transform: translate(-20px, -50%) scale(1.2);
  }
  .one3d-lhs-menu:hover,
  .hover-slide-lhs {
    -webkit-transform: translate(-20px, -50%) scale(1.2);
    -moz-transform: translate(-20px, -50%) scale(1.2);
    -ms-transform: translate(-20px, -50%) scale(1.2);
    -o-transform: translate(-20px, -50%) scale(1.2);
    transform: translate(20px, -50%) scale(1.2);
  }
  .one3d-brand-logo svg {
    width: 200px;
    height: 100px;
  }
  .one3d-sound-button-contain {
    left: 74px;
  }
  .one3d-features-button-box {
    left: 256px;
  }
  .one3d-book-now-box {
    right: 100px;
  }
  .one3d-button-test-drive-box {
    right: 360px;
  }
  .one3d-explore-360-button-box {
    left: 500px;
  }
  .one3d-common-button-box {
    bottom: 60px;
    -webkit-transform: translateY(400%) scale(1.8);
    -moz-transform: translateY(400%) scale(1.8);
    -ms-transform: translateY(400%) scale(1.8);
    -o-transform: translateY(400%) scale(1.8);
    transform: translateY(400%) scale(1.8);
  }
  .show-common-button {
    -webkit-transform: translateY(0) scale(1.8);
    -moz-transform: translateY(0) scale(1.8);
    -ms-transform: translateY(0) scale(1.8);
    -o-transform: translateY(0) scale(1.8);
    transform: translateY(0) scale(1.8);
  }
  .one3d-color-list {
    bottom: 40px;
    -webkit-transform: translate(-50%, 400%) scale(1.8);
    -moz-transform: translate(-50%, 400%) scale(1.8);
    -ms-transform: translate(-50%, 400%) scale(1.8);
    -o-transform: translate(-50%, 400%) scale(1.8);
    transform: translate(-50%, 400%) scale(1.8);
  }
  .show-color-ui {
    -webkit-transform: translate(-50%, 0%) scale(1.8);
    -moz-transform: translate(-50%, 0%) scale(1.8);
    -ms-transform: translate(-50%, 0%) scale(1.8);
    -o-transform: translate(-50%, 0%) scale(1.8);
    transform: translate(-50%, 0%) scale(1.8);
  }
  .one3d-main-loader-percentage {
    -webkit-transform: translate(-50%, -50%) scale(1.8);
    -moz-transform: translate(-50%, -50%) scale(1.8);
    -ms-transform: translate(-50%, -50%) scale(1.8);
    -o-transform: translate(-50%, -50%) scale(1.8);
    transform: translate(-50%, -50%) scale(1.8);
  }
  .one3d-speaker-cursor {
    transform: scale(1.6);
    position: relative;
    bottom: 40px;
  }
  .one3d-tutorial {
    bottom: 120px;
    -webkit-transform: translate(-50%, 0) scale(1.5);
    -moz-transform: translate(-50%, 0) scale(1.5);
    -ms-transform: translate(-50%, 0) scale(1.5);
    -o-transform: translate(-50%, 0) scale(1.5);
    transform: translate(-50%, 0) scale(1.5);
  }
  .one3d-tutorial-tap-drag-adas-screen {
    top: 120px;
    bottom: auto;
  }
  .one3d-tap-to-unmute-button {
    bottom: 80px;
    -webkit-transform: translate(-50%, 0) scale(1.8);
    -moz-transform: translate(-50%, 0) scale(1.8);
    -ms-transform: translate(-50%, 0) scale(1.8);
    -o-transform: translate(-50%, 0) scale(1.8);
    transform: translate(-50%, 0) scale(1.8);
  }
  .one3d-drive-mode-button-box,
  .one3d-park-mode-button-box {
    bottom: 80px;
    -webkit-transform: translate(-50%, 0) scale(1.4);
    -moz-transform: translate(-50%, 0) scale(1.4);
    -ms-transform: translate(-50%, 0) scale(1.4);
    -o-transform: translate(-50%, 0) scale(1.4);
    transform: translate(-50%, 0) scale(1.4);
  }
  .persona-copy,
  .suv-coupe-copy {
    font-size: 40px;
  }
  .one3d-curv-ev-animation {
    font-size: 160px;
  }
  .text-animation {
    font-size: 140px;
  }
  .suv-coupe-text-container {
    top: 1%;
  }
  .suv-coupe-text-container .coupe-text {
    font-size: 168px;
  }
  .one3d-features-container {
    -webkit-transform: translate(0, -50%) scale(1.4);
    -moz-transform: translate(0, -50%) scale(1.4);
    -ms-transform: translate(0, -50%) scale(1.4);
    -o-transform: translate(0, -50%) scale(1.4);
    transform: translate(0, -50%) scale(1.4);
  }
}
// Zoom out : 33%   === Absolute Width : 4608
@media only screen and (min-width: 4000px) and (max-width: 5000px) {
  .one3d-rhs-menu {
    right: 100px;
    -webkit-transform: translate(0, -50%) scale(1.4);
    -moz-transform: translate(0, -50%) scale(1.4);
    -ms-transform: translate(0, -50%) scale(1.4);
    -o-transform: translate(0, -50%) scale(1.4);
    transform: translate(0, -50%) scale(1.4);
  }
  .one3d-lhs-menu {
    left: 120px;
    -webkit-transform: translate(0, -50%) scale(1.4);
    -moz-transform: translate(0, -50%) scale(1.4);
    -ms-transform: translate(0, -50%) scale(1.4);
    -o-transform: translate(0, -50%) scale(1.4);
    transform: translate(0, -50%) scale(1.4);
  }
  .one3d-brand-logo svg {
    width: 400px;
    height: 200px;
  }
  .one3d-rhs-menu:hover,
  .hover-slide-rhs {
    -webkit-transform: translate(-20px, -50%) scale(1.4);
    -moz-transform: translate(-20px, -50%) scale(1.4);
    -ms-transform: translate(-20px, -50%) scale(1.4);
    -o-transform: translate(-20px, -50%) scale(1.4);
    transform: translate(-20px, -50%) scale(1.4);
  }
  .one3d-lhs-menu:hover,
  .hover-slide-lhs {
    -webkit-transform: translate(20px, -50%) scale(1.4);
    -moz-transform: translate(20px, -50%) scale(1.4);
    -ms-transform: translate(20px, -50%) scale(1.4);
    -o-transform: translate(20px, -50%) scale(1.4);
    transform: translate(20px, -50%) scale(1.4);
  }
  .one3d-sound-button-contain {
    left: 120px;
  }
  .one3d-book-now-box {
    right: 188px;
  }
  .one3d-button-test-drive-box {
    right: 580px;
  }
  .one3d-features-button-box {
    left: 392px;
  }
  .one3d-explore-360-button-box {
    left: 750px;
  }
  .one3d-common-button-box {
    bottom: 80px;
    -webkit-transform: translateY(400%) scale(2.6);
    -moz-transform: translateY(400%) scale(2.6);
    -ms-transform: translateY(400%) scale(2.6);
    -o-transform: translateY(400%) scale(2.6);
    transform: translateY(400%) scale(2.6);
  }
  .show-common-button {
    -webkit-transform: translateY(0) scale(2.6);
    -moz-transform: translateY(0) scale(2.6);
    -ms-transform: translateY(0) scale(2.6);
    -o-transform: translateY(0) scale(2.6);
    transform: translateY(0) scale(2.6);
  }
  .one3d-color-list {
    bottom: 60px;
    -webkit-transform: translate(-50%, 500%) scale(2.6);
    -moz-transform: translate(-50%, 500%) scale(2.6);
    -ms-transform: translate(-50%, 500%) scale(2.6);
    -o-transform: translate(-50%, 500%) scale(2.6);
    transform: translate(-50%, 500%) scale(2.6);
  }
  .show-color-ui {
    -webkit-transform: translate(-50%, 0%) scale(2.6);
    -moz-transform: translate(-50%, 0%) scale(2.6);
    -ms-transform: translate(-50%, 0%) scale(2.6);
    -o-transform: translate(-50%, 0%) scale(2.6);
    transform: translate(-50%, 0%) scale(2.6);
  }
  .one3d-main-loader-percentage {
    -webkit-transform: translate(-50%, -50%) scale(2.6);
    -moz-transform: translate(-50%, -50%) scale(2.6);
    -ms-transform: translate(-50%, -50%) scale(2.6);
    -o-transform: translate(-50%, -50%) scale(2.6);
    transform: translate(-50%, -50%) scale(2.6);
  }
  .one3d-speaker-cursor {
    -webkit-transform: scale(2.6);
    -moz-transform: scale(2.6);
    -ms-transform: scale(2.6);
    -o-transform: scale(2.6);
    transform: scale(2.6);
    position: relative;
    bottom: 140px;
  }
  .one3d-cursor {
    width: 100px;
    height: 100px;
    border-width: 6px;
  }
  .one3d-cursor-point {
    width: 24px;
    height: 24px;
  }
  .one3d-tutorial {
    bottom: 150px;
    -webkit-transform: translate(-50%, 0) scale(2.2);
    -moz-transform: translate(-50%, 0) scale(2.2);
    -ms-transform: translate(-50%, 0) scale(2.2);
    -o-transform: translate(-50%, 0) scale(2.2);
    transform: translate(-50%, 0) scale(2.2);
  }
  .one3d-tutorial-tap-drag-adas-screen {
    top: 150px;
    bottom: auto;
  }
  .one3d-tap-to-unmute-button {
    bottom: 150px;
    -webkit-transform: translate(-50%, 0) scale(2.6);
    -moz-transform: translate(-50%, 0) scale(2.6);
    -ms-transform: translate(-50%, 0) scale(2.6);
    -o-transform: translate(-50%, 0) scale(2.6);
    transform: translate(-50%, 0) scale(2.6);
  }
  .one3d-drive-mode-button-box,
  .one3d-park-mode-button-box {
    bottom: 120px;
    -webkit-transform: translate(-50%, 0) scale(2.6);
    -moz-transform: translate(-50%, 0) scale(2.6);
    -ms-transform: translate(-50%, 0) scale(2.6);
    -o-transform: translate(-50%, 0) scale(2.6);
    transform: translate(-50%, 0) scale(2.6);
  }
  .persona-copy,
  .suv-coupe-copy {
    font-size: 50px;
  }
  .one3d-curv-ev-animation {
    font-size: 250px;
  }
  .text-animation {
    font-size: 200px;
  }
  .suv-coupe-text-container {
    top: 1%;
  }
  .suv-coupe-text-container .coupe-text {
    font-size: 268px;
  }
  .one3d-features-container {
    -webkit-transform: translate(0, -50%) scale(1.5);
    -moz-transform: translate(0, -50%) scale(1.5);
    -ms-transform: translate(0, -50%) scale(1.5);
    -o-transform: translate(0, -50%) scale(1.5);
    transform: translate(0, -50%) scale(1.5);
  }
}
// Zoom out : 25%   === Absolute Width : 6144
@media only screen and (min-width: 5000px) {
  .one3d-rhs-menu {
    right: 180px;
    -webkit-transform: translate(0, -50%) scale(1.5);
    -moz-transform: translate(0, -50%) scale(1.5);
    -ms-transform: translate(0, -50%) scale(1.5);
    -o-transform: translate(0, -50%) scale(1.5);
    transform: translate(0, -50%) scale(1.5);
  }
  .one3d-brand-logo svg {
    width: 400px;
    height: 200px;
  }
  .one3d-lhs-menu {
    left: 200px;
    -webkit-transform: translate(0, -50%) scale(1.5);
    -moz-transform: translate(0, -50%) scale(1.5);
    -ms-transform: translate(0, -50%) scale(1.5);
    -o-transform: translate(0, -50%) scale(1.5);
    transform: translate(0, -50%) scale(1.5);
  }
  .one3d-rhs-menu:hover,
  .hover-slide-rhs {
    -webkit-transform: translate(-20px, -50%) scale(1.5);
    -moz-transform: translate(-20px, -50%) scale(1.5);
    -ms-transform: translate(-20px, -50%) scale(1.5);
    -o-transform: translate(-20px, -50%) scale(1.5);
    transform: translate(-20px, -50%) scale(1.5);
  }
  .one3d-lhs-menu:hover,
  .hover-slide-lhs {
    -webkit-transform: translate(20px, -50%) scale(1.5);
    -moz-transform: translate(20px, -50%) scale(1.5);
    -ms-transform: translate(20px, -50%) scale(1.5);
    -o-transform: translate(20px, -50%) scale(1.5);
    transform: translate(20px, -50%) scale(1.5);
  }
  .one3d-sound-button-contain {
    left: 240px;
  }
  .one3d-features-button-box {
    left: 660px;
  }
  .one3d-explore-360-button-box {
    left: 1218px;
  }
  .one3d-book-now-box {
    right: 300px;
  }
  .one3d-button-test-drive-box {
    right: 900px;
  }
  .one3d-common-button-box {
    bottom: 120px;
    -webkit-transform: translateY(400%) scale(4);
    -moz-transform: translateY(400%) scale(4);
    -ms-transform: translateY(400%) scale(4);
    -o-transform: translateY(400%) scale(4);
    transform: translateY(400%) scale(4);
  }
  .show-common-button {
    -webkit-transform: translateY(0) scale(4);
    -moz-transform: translateY(0) scale(4);
    -ms-transform: translateY(0) scale(4);
    -o-transform: translateY(0) scale(4);
    transform: translateY(0) scale(4);
  }
  .one3d-color-list {
    bottom: 100px;
    -webkit-transform: translate(-50%, 700%) scale(3.5);
    -moz-transform: translate(-50%, 700%) scale(3.5);
    -ms-transform: translate(-50%, 700%) scale(3.5);
    -o-transform: translate(-50%, 700%) scale(3.5);
    transform: translate(-50%, 700%) scale(3.5);
  }
  .show-color-ui {
    -webkit-transform: translate(-50%, 0%) scale(3.5);
    -moz-transform: translate(-50%, 0%) scale(3.5);
    -ms-transform: translate(-50%, 0%) scale(3.5);
    -o-transform: translate(-50%, 0%) scale(3.5);
    transform: translate(-50%, 0%) scale(3.5);
  }
  .one3d-main-loader-percentage {
    -webkit-transform: translate(-50%, -50%) scale(4.5);
    -moz-transform: translate(-50%, -50%) scale(4.5);
    -ms-transform: translate(-50%, -50%) scale(4.5);
    -o-transform: translate(-50%, -50%) scale(4.5);
    transform: translate(-50%, -50%) scale(4.5);
  }
  .one3d-speaker-cursor {
    -webkit-transform: scale(3.5);
    -moz-transform: scale(3.5);
    -ms-transform: scale(3.5);
    -o-transform: scale(3.5);
    transform: scale(3.5);
    position: relative;
    bottom: 200px;
  }
  .one3d-cursor {
    width: 100px;
    height: 100px;
    border-width: 6px;
  }
  .one3d-cursor-point {
    width: 24px;
    height: 24px;
  }
  .one3d-tutorial {
    bottom: 150px;
    -webkit-transform: translate(-50%, 0) scale(2.6);
    -moz-transform: translate(-50%, 0) scale(2.6);
    -ms-transform: translate(-50%, 0) scale(2.6);
    -o-transform: translate(-50%, 0) scale(2.6);
    transform: translate(-50%, 0) scale(2.6);
  }
  .one3d-tutorial-tap-drag-adas-screen {
    top: 150px;
    bottom: auto;
  }
  .one3d-tap-to-unmute-button {
    bottom: 150px;
    -webkit-transform: translate(-50%, 0) scale(2.6);
    -moz-transform: translate(-50%, 0) scale(2.6);
    -ms-transform: translate(-50%, 0) scale(2.6);
    -o-transform: translate(-50%, 0) scale(2.6);
    transform: translate(-50%, 0) scale(2.6);
  }
  .one3d-drive-mode-button-box,
  .one3d-park-mode-button-box {
    bottom: 140px;
    -webkit-transform: translate(-50%, 0) scale(2.6);
    -moz-transform: translate(-50%, 0) scale(2.6);
    -ms-transform: translate(-50%, 0) scale(2.6);
    -o-transform: translate(-50%, 0) scale(2.6);
    transform: translate(-50%, 0) scale(2.6);
  }
  .persona-copy,
  .suv-coupe-copy {
    font-size: 50px;
  }
  .one3d-curv-ev-animation {
    font-size: 250px;
  }
  .text-animation {
    font-size: 200px;
  }
  .suv-coupe-text-container {
    top: 1%;
  }
  .suv-coupe-text-container .coupe-text {
    font-size: 268px;
  }
  .one3d-features-container {
    -webkit-transform: translate(0, -50%) scale(1.6);
    -moz-transform: translate(0, -50%) scale(1.6);
    -ms-transform: translate(0, -50%) scale(1.6);
    -o-transform: translate(0, -50%) scale(1.6);
    transform: translate(0, -50%) scale(1.6);
  }
}
@media only screen and (max-width: 991px) {
  .one3d-curv-ev-animation {
    font-size: 46px;
  }
  .one3d-brand-logo {
    top: 0;
    left: 0;
    -webkit-transform: scale(0.7);
    -moz-transform: scale(0.7);
    -ms-transform: scale(0.7);
    -o-transform: scale(0.7);
    transform: scale(0.7);
  }
  .one3d-color-name {
    font-size: 12px;
  }
  .one3d-color-swatches-li {
    width: 36px;
    height: 36px;
    margin: 0 2px;
  }
  .one3d-color-swatch-circle {
    width: 36px;
    height: 36px;
  }
  .one3d-color-car-dual-tone {
    width: 28px;
    height: 28px;
  }
  .one3d-color-car-roof,
  .one3d-color-car-body {
    width: 28px;
    height: 14px;
  }
  .one3d-common-button {
    font-size: 10px;
    padding: 10px;
  }
  .one3d-book-now-box {
    right: 10px;
  }
  .one3d-sound-button-contain {
    left: 10px;
  }
  .one3d-button-test-drive-box {
    right: 90px;
  }
  .one3d-features-button-box {
    left: 56px;
  }
  .one3d-explore-360-button-box {
    left: 132px;
  }
  .one3d-common-button-box {
    bottom: 14px;
  }
  .one3d-lhs-menu-mobile,
  .one3d-rhs-menu-mobile {
    display: block;
  }
  .one3d-lhs-menu,
  .one3d-rhs-menu {
    display: none;
  }
  .selected {
    -webkit-transform: translateY(-2px);
    -moz-transform: translateY(-2px);
    -ms-transform: translateY(-2px);
    -o-transform: translateY(-2px);
    transform: translateY(-2px);
  }
  .one3d-tap-to-unmute-button {
    bottom: -12px;
    -webkit-transform: translate(-50%, 0) scale(0.8);
    -moz-transform: translate(-50%, 0) scale(0.8);
    -ms-transform: translate(-50%, 0) scale(0.8);
    -o-transform: translate(-50%, 0) scale(0.8);
    transform: translate(-50%, 0) scale(0.8);
  }
  .one3d-speaker-cursor-box {
    display: none;
  }
  .one3d-tap-to-unmute {
    -webkit-transform: scale(0.8);
    -moz-transform: scale(0.8);
    -ms-transform: scale(0.8);
    -o-transform: scale(0.8);
    transform: scale(0.8);
  }
  .one3d-tap-anywhere-to-unmute {
    right: 0px;
    top: -12px;
  }
  .one3d-main-loader-percentage {
    top: 26%;
  }
  .one3d-loader-percentage-text {
    --text-size: clamp(6rem, 6vw + 6rem, 6rem);
  }
  .text-animation {
    font-size: 30px;
  }
  .persona-copy {
    font-size: 12px;
    flex-wrap: wrap;
    margin: 2px 0 0;
  }
  .suv-coupe-text-container .coupe-text {
    font-size: 40px;
  }
  .one3d-drive-mode-button-box {
    bottom: 14px;
  }
  .one3d-drive-mode-button-left {
    width: 42px;
    height: 42px;
  }
  .one3d-drive-mode-wheel-air {
    width: 28px;
  }
  .one3d-drive-mode-wheel {
    left: 15px;
    top: 9px;
  }
  .one3d-drive-mode-button {
    height: 54px;
    width: 140px;
  }
  .one3d-drive-mode-button-right {
    width: calc(100% - 42px);
  }
  .one3d-drive-mode-button-text {
    font-size: 12px;
  }
  .one3d-drive-mode-button-arrows-svg {
    width: 75px;
  }
  .one3d-drive-mode-wheel-image {
    width: 20px;
  }
  .one3d-park-mode-button-box {
    bottom: 10px;
  }
  .one3d-park-mode-button-right {
    width: 42px;
    height: 42px;
  }
  .one3d-park-mode-wheel-air {
    width: 28px;
  }
  .one3d-drive-mode-wheel {
    left: 15px;
    top: 9px;
  }
  .one3d-park-mode-button {
    height: 54px;
    width: 140px;
  }
  .one3d-park-mode-button-left {
    width: calc(100% - 42px);
    padding-right: 6px;
  }
  .one3d-park-mode-button-text {
    font-size: 12px;
  }
  .one3d-park-mode-button-right-svg {
    width: 28px;
  }
  .one3d-arrow-anim-park {
    width: 70px;
  }
  .one3d-tutorial {
    bottom: 10px;
    -webkit-transform: translate(-50%, 0) scale(0.7);
    -moz-transform: translate(-50%, 0) scale(0.7);
    -ms-transform: translate(-50%, 0) scale(0.7);
    -o-transform: translate(-50%, 0) scale(0.7);
    transform: translate(-50%, 0) scale(0.7);
  }
  .one3d-tutorial-tap-drag-adas-screen {
    top: 10px;
    bottom: auto;
  }
  .button-sound-bar {
    height: 18px;
  }
  .one3d-button-muted .button-sound-bar {
    height: 10px;
  }
  .one3d-color-name-display {
    bottom: 38px;
  }
  .one3d-color-list {
    bottom: 10px;
  }
  .one3d-cursor {
    display: none !important;
  }
  .suv-coupe-copy {
    margin: 0;
    font-size: 12px;
  }
  .suv-coupe-copy::before,
  .suv-coupe-copy::after {
    width: 18px;
    height: 1px;
    left: -28px;
  }
  .suv-coupe-copy::after {
    right: -28px;
    left: auto;
  }
  .suv-coupe-text-container,
  .one3d-text-container {
    top: 4px;
  }
  .one3d-button-sound-ul {
    height: 15px;
  }
  .adas-screen-activated {
    top: 16px;
  }
  .adas-screen-activated .text-animation::after {
    width: 6px;
    right: -8px;
    height: 6px;
  }
  .one3d-iphone-device .one3d-lhs-menu-mobile {
    left: 14px;
  }
  .button-sound-bar:nth-child(6),
  .button-sound-bar:nth-child(7),
  .button-sound-bar:nth-child(8),
  .button-sound-bar:nth-child(9),
  .button-sound-bar:nth-child(10) {
    display: none;
  }
  .one3d-features-container {
    width: 152px;
    left: 2vw;
  }
  .one3d-features-heading {
    top: -20px;
    font-size: 12px;
  }
  .one3d-features-content-li {
    font-size: 12px;
    line-height: 1.4;
  }
  .one3d-features-content-container {
    padding: 10px;
    background-image: none;
    border: 1px solid rgba(255, 255, 255, 0.6);
  }
  .one3d-disclaimer {
    font-size: 7px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1199px) and (orientation: landscape) {
  .one3d-button-test-drive-box {
    right: 94px;
  }
  .one3d-cursor {
    display: none !important;
  }
  .one3d-button-sound-ul {
    height: 15px;
  }
  .one3d-features-container {
    width: 220px;
    left: 4vw;
  }
  .one3d-features-content-li {
    font-size: 16px;
  }
  .one3d-features-heading {
    font-size: 16px;
  }
  .one3d-disclaimer {
    font-size: 7px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) and (orientation: landscape) {
  .one3d-button-test-drive-box {
    right: 154px;
  }
  .button-sound-bar:nth-child(6),
  .button-sound-bar:nth-child(7),
  .button-sound-bar:nth-child(8),
  .button-sound-bar:nth-child(9),
  .button-sound-bar:nth-child(10) {
    display: none;
  }
  .one3d-features-button-box {
    left: 86px;
  }
  .one3d-explore-360-button-box {
    left: 212px;
  }
  .one3d-features-container {
    width: 180px;
  }
  .one3d-features-heading {
    font-size: 16px;
  }
  .one3d-features-content-li {
    font-size: 16px;
    line-height: 1.4;
  }
  .one3d-features-content-container {
    padding: 10px;
    background-image: none;
    border: 1px solid rgba(255, 255, 255, 0.6);
  }
  .one3d-color-swatches-li {
    margin: 0 2px;
  }
  .one3d-button-sound-ul {
    height: 23px;
  }
}
@media only screen and (min-width: 700px) and (max-width: 800px) and (orientation: landscape) {
  .one3d-features-container {
    left: 5vw;
  }
}
// iPad Pro only
@media only screen and (min-device-width: 1366px) and (max-device-width: 1366px) and (orientation: landscape) {
  .one3d-features-container {
    left: 20px;
    width: 250px;
  }
  .one3d-features-content-container {
    padding: 10px;
    background-image: none;
    border: 1px solid rgba(255, 255, 255, 0.6);
  }
}
// iPad Pro only
@media only screen and (min-device-width: 1194px) and (max-device-width: 1194px) and (orientation: landscape) {
  .one3d-features-container {
    left: 20px;
    width: 250px;
  }
  .one3d-features-content-container {
    padding: 10px;
    background-image: none;
    border: 1px solid rgba(255, 255, 255, 0.6);
  }
}
// iPad Pro only
@media only screen and (min-device-width: 1024px) and (max-device-width: 1024px) and (orientation: landscape) {
  .one3d-features-container {
    left: 20px;
    width: 180px;
  }
  .one3d-features-content-container {
    padding: 10px;
    background-image: none;
    border: 1px solid rgba(255, 255, 255, 0.6);
  }
}
@media only screen and (min-device-width: 1194px) and (max-device-width: 1194px) and (orientation: portrait) {
  .one3d-lhs-menu-mobile,
  .one3d-rhs-menu-mobile {
    display: none;
  }
  .one3d-lhs-menu,
  .one3d-rhs-menu {
    display: block;
  }
}
@media only screen and (min-device-width: 1024px) and (max-device-width: 1024px) and (orientation: portrait) {
  .one3d-lhs-menu-mobile,
  .one3d-rhs-menu-mobile {
    display: none;
  }
  .one3d-lhs-menu,
  .one3d-rhs-menu {
    display: block;
  }
}
@media only screen and (min-device-width: 820px) and (max-device-width: 820px) and (orientation: portrait) {
  .one3d-lhs-menu-mobile,
  .one3d-rhs-menu-mobile {
    display: none;
  }
  .one3d-lhs-menu,
  .one3d-rhs-menu {
    display: block;
  }
}
@media only screen and (min-device-width: 768px) and (max-device-width: 768px) and (orientation: portrait) {
  .one3d-lhs-menu-mobile,
  .one3d-rhs-menu-mobile {
    display: none;
  }
  .one3d-lhs-menu,
  .one3d-rhs-menu {
    display: block;
  }
}
@media only screen and (max-width: 767px) and (orientation: portrait) {
  .one3d-sound-button-contain {
    bottom: auto;
    left: auto;
    right: 7px;
    top: 10px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) and (orientation: portrait) {
  .one3d-sound-button-contain {
    bottom: auto;
    left: auto;
    right: 10px;
    top: 20px;
  }
}
